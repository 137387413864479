import {store} from 'store/store';
import {role} from 'config/Enums/user';
import Api from 'api/Api';

class UserHelper {

    constructor(){
        this.store = store;
    }

    async isAuthenticated(){
        let state = this.store.getState()
        const auth = state.users.auth
        if(auth.access_token !== "" && auth.expiry !== "" && auth.client !== "" && auth.uid !== ""){
            try{
                let resp = await Api.Authentication.validateToken(auth.access_token)
                return resp.success;
            }catch(error){
                return false;
            }
        }else{
            return false
        }
    }

    translateRole(string){
        let translated = role[string];
        if(translated === undefined){
            return ""
        }else{
            return translated
        }
    }
}

export default UserHelper