import React from 'react';
import ColorsTable from './components/ColorsTable'
class ColorsIndex extends React.Component {

    render(){
        return(
            <ColorsTable/>
        );
    }
}

export default ColorsIndex;