import { createStore, compose } from 'redux';
import { persistStore } from 'redux-persist';
import { combinedReducers } from 'store/combinedReducers';

    
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore( combinedReducers, 
    composeEnhancer())

export const persistor = persistStore(store);