import React from 'react';
import { Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

class ButtonWithLoading extends React.Component {

    constructor(props){
        super(props);
        this.getContent = this.getContent.bind(this);
        this.state = {
            disabled: (this.props.disabled !== undefined) ? this.props.disabled : false
        }
    }
    
    getContent(){
        if(this.props.loading === true){
           return <Spinner size="sm" color="black" />;
        }else{
           return this.props.children;
        }
    }

    render(){
        let {loading, ...parentProps} = this.props;
        return(
            <Button data={this.state} 
                        disabled={this.props.loading} 
                        {...parentProps}>
                {this.getContent()}
            </Button>
        )
    }
}

ButtonWithLoading.propTypes = {
    loading: PropTypes.bool
};

export default ButtonWithLoading;