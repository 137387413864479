import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import PropTypes from 'prop-types';
class ActiveIcon extends React.Component {


    getIcon(){
        if(this.props.value){
            return <CheckIcon className="text-success"/>
        }else{
            return <CloseIcon className="text-danger"/>
        }
    }
    getColor(){
        if(this.props.value){
            return "success"
        }else{
            return "danger"
        }
    }

    render(){
        return(
            <React.Fragment>
                {/* <Badge color={this.getColor()} pill> */}
                    {this.getIcon()}
                {/* </Badge>             */}
            </React.Fragment>
        );
    }
}

ActiveIcon.propTypes = {
    value: PropTypes.bool.isRequired,
};

export default ActiveIcon;
