
import * as actionTypes from 'store/actions/finishes';

const initialState = {
    finishes: [],
    finish: {
        description_pt: '',
        description_en: '',
        description_es: '',
        active: false,
        sequency: '',
        image: {
            filename: "",
            file: "",
            url: ""
        },
        code: '',
        error: {
            image: "",
            description_pt: '',
            description_en: '',
            description_es: '',
            sequency: '',
            active: '',
            code: ''
        },
        valid: {
            image: true,
            description_pt: true,
            description_en: true,
            description_es: true,
            active: true,
            sequency: true,
            code: true
        }
    }
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.TOGGLE_ACTIVE:
            return {
                ...state,
                finish: {
                    ...state.finish,
                    active: !state.finish.active
                }
            }
        case actionTypes.RESET_FINISHES: 
            return {
                ...state, 
                finishes: []
            }
        case actionTypes.RESET_FINISH: 
            return {
                ...state, 
                finish: initialState.finish
            }
        case actionTypes.SET_FINISHES:
            return {
                ...state,
                finishes: action.payload.finishes
            }
        case actionTypes.SET_FINISH:
            return {
                ...state,
                finish: {
                    ...state.finish,
                    ...action.payload.finish
                }
            }
        case actionTypes.SET_ERROR:
            return {
                ...state,
                finish: {
                    ...state.finish, 
                    error: {
                        ...state.finish.error,
                        [action.payload.name]: action.payload.error
                    },
                    valid: {
                        ...state.finish.valid,
                        [action.payload.name]: (action.payload.error.length === 0 ? true : false)
                    }
                }
            }
        case actionTypes.CHANGE_ATTR:
            return {
                ...state,
                finish: {
                    ...state.finish,
                    [action.payload.name]: action.payload.value    
                }
            }
        default:
            return state;
    }
}
export default reducer;