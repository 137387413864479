import * as yup from 'yup'
import "config/yup/pt-BR/locale_pt-BR.js"

function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
  }

const finishesFormValidation = yup.object().shape({
    description_pt: yup.string().required(),
    description_en: yup.string().required(),
    sequency: yup.number().transform(emptyStringToNull).nullable(),
    description_es: yup.string().required(),
    active: yup.boolean().required(),
    code: yup.string().required(),
    image: yup.object().shape({
        url: yup.string(),
        file: yup.string().when('url', {
          is: (url) => url !== "" , // alternatively: (val) => val == true
          then: yup.string(),
          otherwise: yup.string().required(),
        }),
        filename: yup.string().when('url', {
          is: (url) => url !== "" , // alternatively: (val) => val == true
          then: yup.string(),
          otherwise: yup.string().required(),
        }),
    })
});

export default finishesFormValidation;