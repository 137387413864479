import FinishesApi from "./Finishes";
import ColorsApi from "./Colors";
import MenuItemsApi from './MenuItems';
import LogsApi from './Logs';
import SettingsApi from './Settings';
import AuthenticationApi from './Authentication';
import TermsOfUse from './TermsOfUse';
import Users from './Users';
import HelpQuestions from './HelpQuestions';

export default {
    Finishes: new FinishesApi(),
    Colors: new ColorsApi(),
    Logs: new LogsApi(),
    MenuItems: new MenuItemsApi(),
    Settings: new SettingsApi(),
    Authentication: new AuthenticationApi(),
    TermsOfUse: new TermsOfUse(),
    Users: new Users(),
    HelpQuestions: new HelpQuestions()
}