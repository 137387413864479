
import React from 'react';
import MaterialTable from "material-table";
import {baseOptions, translations} from 'config/MaterialTable/config';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from 'store/actions/colors'
import Api from 'api/Api'
import {toast} from 'react-toastify';
import ActiveIcon from 'components/ActiveIcon'

class ColorsTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true, 
			actions:[
				{
            		isFreeAction: true,
					icon: 'add',
                    tooltip: 'Adicionar Cor',
                    onClick: () => {
                        this.props.actions.reset()
                        this.props.history.push("/colors/create") 
                    },
                    iconProps: {
                        className: "text-success icon-lg",
                    }
				},
                rowData => ({
                    icon: 'edit',
                    tooltip: 'Editar Cor',
                    onClick: this.edit,
                    iconProps: {
                        className: "text-warning"
                    }
                })
            ]
        }
        this.edit = this.edit.bind(this);
    }
    
    componentDidMount(){
        this.props.actions.resetColors()
        Api.Colors.all().then( (resp) => {
            let {data} = resp;
            this.props.actions.setColors(data);
            this.setState({loading: false})
        }).catch( (error) => {
            this.setState({loading: false})
            console.error(error);
            toast.error("Ops! Algo deu errado!");
        });
    }

    edit(event, rowData){
        this.props.actions.reset()
		this.props.history.push('/colors/update/' + rowData.id);	
    }
    
    render(){
        return(
            <div style={{ maxWidth: "100%" }}>
            <MaterialTable 
                columns={ columns } 
                title={ "Cores" }
                data={ this.props.colors }
                options={ baseOptions }
                isLoading={ this.state.loading}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                actions={ this.state.actions }
                localization={ translations }
            />
        </div>
        );
    }
};



const columns = [
    { title: "Código", field: "code" },
    { title: "Acabamento", field: "finish_name"},
    { title: "Descrição", field: "description_pt" },
    { title: "Ativo", field: "active", render: (rowData) => <ActiveIcon value={rowData.active}></ActiveIcon>}
]

const mapStateToProps = (state) => {
    return {
         colors: state.colors.colorsList
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
            actions: {
                resetColors: () => {
                    return dispatch({
                        type: actions.RESET_COLOR_LIST
                    })
                },
                reset:() => {
                    return dispatch({
                        type: actions.RESET_STATE
                    })
                },
                setColors: (colors) => {
                    return dispatch({
                        type: actions.SET_COLOR_LIST,
                        payload: {
                            colors: colors
                        }
                    });
                }
            }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ColorsTable));