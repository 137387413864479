import React from 'react';
import Api from 'api/Api';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {ErrorField} from 'components/Forms'

class BasicSelect extends React.Component{

    constructor(props){
        super(props);
        this.selectFromOptions = this.selectFromOptions.bind(this);
    }
    
    selectFromOptions(id){
        let option = this.props.options.filter(option => option.value === id);
        return (option.length === 0 ) ? {} : option
    }


    render(){
        return(
            <React.Fragment>
                <Select {...this.props} 
                        onChange={this.props.onChange}
                        value={this.selectFromOptions(this.props.value)}
                        options={this.props.options}>
                </Select>
            </React.Fragment>
        );
    }
}

BasicSelect.defaultProps = {
    value: ""
}

BasicSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default BasicSelect;