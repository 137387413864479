import * as actionTypes from 'store/actions/colors';
import * as moment from 'moment';
import color from '@material-ui/core/colors/amber';

const initialState = {
    colorsList: [],
    color: {
        code: "",
        description_pt: "",
        description_en: "",
        description_es: "",
        detailed_description_pt: "",
        detailed_description_en: "",
        detailed_description_es: "",
        active: false,
        geometry: '',
        image_format: "",
        image_orientation: "",
        image_initial_date: moment().format('YYYY-MM-DD'),
        image_final_date: moment().add("50", 'years').format('YYYY-MM-DD'),
        
        finish_id: undefined,
        images: []
    },
    images:{
        vertical: {
            id: null,
            image_orientation: "vertical",
            shadow: false,
            geometry: null,
            url: "",
            enabled: false
        },
        vertical_with_shadow: {
            id: null,
            image_orientation: "vertical",
            shadow: true,
            geometry: null,
            url: "" ,
            enabled: false
        },
        horizontal: {
            id: null,
            image_orientation: "horizontal",
            shadow: false,
            geometry: null,
            url: "",
            enabled: false
        },
        horizontal_with_shadow: {
            id: null,
            image_orientation: "horizontal",
            shadow: true,
            geometry: null,
            url: "",
            enabled: false
        }
    },
    errors:{
        code: "",
        description_pt: "",
        description_en: "", 
        description_es: "",
        detailed_description_pt: "",
        detailed_description_en: "",
        detailed_description_es: "",
        active: "",
        geometry: "",
        image_format: "",
        image_initial_date: "",
        image_final_date: "",
        image_orientation: "",
        finish_id: "",
        horizontal_with_shadow: "",
        horizontal: "",
        vertical_with_shadow: ""
    },
}

const changeOrientation = (orientation, state) => {
    let images = {...state.images}
    switch(orientation){
        case("horizontal"):
            return {
                ...images,
                horizontal_with_shadow:{ 
                    ...images.horizontal_with_shadow,
                    enabled: true
                },
                horizontal:{ 
                    ...images.horizontal,
                    enabled: true
                },
                vertical_with_shadow:{ 
                    ...images.vertical_with_shadow,
                    enabled: false
                },
                vertical:{
                    ...images.vertical,
                    enabled: false
                }
            }
        case("vertical"):
            return {
                ...images,
                horizontal_with_shadow:{ 
                    ...images.horizontal_with_shadow,
                    enabled: false
                },
                horizontal:{ 
                    ...images.horizontal,
                    enabled: false
                },
                vertical_with_shadow:{ 
                    ...images.vertical_with_shadow,
                    enabled: true
                },
                vertical:{
                    ...images.vertical,
                    enabled: true
                }
            }
        case("both"):
            return {
                ...images,
                horizontal_with_shadow:{ 
                    ...images.horizontal_with_shadow,
                    enabled: true
                },
                horizontal:{ 
                    ...images.horizontal,
                    enabled: true
                },
                vertical_with_shadow:{ 
                    ...images.vertical_with_shadow,
                    enabled: true
                },
                vertical:{
                    ...images.vertical,
                    enabled: true
                }
            }
    }
    return images;
}

const getJsonForImages = (images, state) => {
    if(images.length == 0 ) return {} 
    let json = { ...state.images}
    for(let image of images){
        if(image.image_orientation == "vertical" && image.shadow == true){
            json = {
                ...json,
                vertical_with_shadow: {
                    ...image,
                    enabled: true
                }
            }
        }else if(image.image_orientation == "vertical"){
            json = {
                ...json,
                vertical: {
                    ...image,
                    enabled: true
                }
            }
        }else if(image.image_orientation == "horizontal" && image.shadow == true){
            json = {
                ...json,
                horizontal_with_shadow: {
                    ...image,
                    enabled: true
                }
            }
        }else{
            json = {
                ...json,
                horizontal: {
                    ...image,
                    enabled: true
                }
            }
        }
    }
    return json;
}

const reducer = (state = initialState, action) => {
    switch(action.type){
    case actionTypes.CHANGE_GEOMETRY:
        return {
            ...state,
            images: {
                ...state.images,
                vertical: {
                    ...state.images.vertical,
                    geometry: action.payload.value
                },
                horizontal: {
                    ...state.images.horizontal,
                    geometry: action.payload.value
                },
                vertical_with_shadow: {
                    ...state.images.vertical_with_shadow,
                    geometry: action.payload.value
                },
                horizontal_with_shadow: {
                    ...state.images.horizontal_with_shadow,
                    geometry:    action.payload.value
                }
            }
        
        }
    case actionTypes.SET_IMAGE:
        return {
            ...state,
            color: {
                ...state.color
            },
            images: {
                ...state.images,
                [action.payload.name]: {
                    ...state.images[action.payload.name],
                    file: action.payload.value
                } 
            }
        }
    case actionTypes.SET_COLOR:
        let {id, created_at, update_at, finish_name, images, ...params} = action.payload.data
        return {
            ...initialState,
            color: {
                ...params,
                images: images,
                geometry: images[0].geometry
            },
            images: getJsonForImages(images, initialState),
        }
    case actionTypes.CHANGE_ORIENTATION:
        return {
            ...state,
            color: {
                ...state.color,
                image_orientation: action.payload.value
            },
            images: changeOrientation(action.payload.value, state)
        }
    case actionTypes.CHANGE_ATTR:
        return {
            ...state,
            color:{
                ...state.color,
                [action.payload.name]: action.payload.value
            }
        }
    case actionTypes.SET_ERROR:
        return { 
                ...state, 
                errors: { 
                    ...state.errors,
                    [action.payload.name]: action.payload.value
                }
            }
    case actionTypes.RESET_COLOR_LIST:
        return {
            ...state,
            colorList: []
        }
    case actionTypes.SET_COLOR_LIST:
        return {
            ...state,
            colorsList: action.payload.colors
        }
    case actionTypes.RESET_STATE:
        return {...initialState};
    default:
        return state;
    }
}

export default reducer;
