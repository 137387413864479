import {API_URL, USER_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter, connectionErrorFilter} from '../Filters';
import ApiBase from '../ApiBase'
class UsersApi extends ApiBase{

    constructor(){
        super();
        this.url = API_URL + USER_URL;
    }

    async all(){
        let params = {
            method: 'GET',
            headers: this.headers()
        }
        let url = this.url;
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw new Error({success: false, error: "erro"}); 
        }
    }

    async delete(id){
        let params = {
            headers: this.headers(),
            method: 'DELETE',
        }
        let url = this.url + "/"+ id
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }
    }

    async changeRole(id){
        let params = {
            method: 'POST',
            headers: this.headers()
            
        }
        let url = this.url + '/change_role/' + id 
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            
            return {success: error.succes, status: error.error.status, errors: error.errors}
        }
    }
}

export default UsersApi;