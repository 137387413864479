
import * as actionTypes from 'store/actions/users';
import moment from 'moment';

export const initialState = {
        users: [],
        user: {
            username: '',
            email: '',
            password: '',
            password_confirmation: '',
            error: {
                username: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
            valid: {
                username: true,
                email: true,
                password: true,
                password_confirmation: true,
            }
        },
        auth: {
            username: '',
            access_token: '',
            expiry: '',
            client: '',
            uid: '',
            role: '',
            __persistedAt: ''
        },
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.SET_CREDENTIALS:
            if(action.payload.credentials.role === "user"){
                return {
                    ...state,
                    auth: state.initialState
                }
            }
            return {
                ...state,
                auth: {
                    ...state.auth,
                    access_token: action.payload.credentials["access-token"],
                    ...action.payload.credentials,
                    __persistedAt: moment().toDate()
                }
            }
        case actionTypes.REMOVE_CREDENTIALS:
            return {
                ...state,
                auth: {
                    ...initialState.auth
                },
            }
        case actionTypes.RESET_USERS: 
            return {
                ...state,
                users: []
            }
        case actionTypes.RESET_USER: 
            return {
                ...state, 
                user: initialState.user
            }
        case actionTypes.SET_USERS:
            return {
                ...state,
                users: action.payload.users
            }
        case actionTypes.SET_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload.user
                }
            }
        case actionTypes.SET_ERROR:
            return {
                ...state,
                user: {
                    ...state.user, 
                    error: {
                        ...state.user.error,
                        [action.payload.name]: action.payload.error
                    },
                    valid: {
                        ...state.user.valid,
                        [action.payload.name]: (action.payload.error.length === 0 ? true : false)
                    }
                }
            }
        case actionTypes.CHANGE_ATTR:
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.payload.name]: action.payload.value    
                }
            }
        default:
            return state;
    }
}

export default reducer;