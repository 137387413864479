import React from 'react';
import Api from 'api/Api';

class PrivacyPoliticsPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            terms: ""
        }
    }

    async componentDidMount(){
        try{
            let response = await Api.TermsOfUse.get()
            let terms = response.data.body
            this.setState({terms})
        }catch(error){
            console.error(error);
        }
    }

    render(){
        return(
            <div>
                <h1 className="text-center  mt-2full-width">
                    Política de Privacidade
                </h1>
                <p>
                Ao contratar e acessar os serviços deste Aplicativo você possui as informações disponíveis dos acabamentos e produtos complementares da Florense.
                    As informações que você utilizar e compartilhar com o aplicativo possuem a finalidade de demonstrar visualmente aos clientes as possíveis combinações de acabamentos e produtos complementares ofertados pela FLORENSE, facilitando a escolha.
                    Para isso você consente que seus dados informados (e-mail, conta Facebook e Instagram), sejam utilizados único e exclusivamente para acesso ao aplicativo.
                    Sendo que os dados não serão compartilhados, somente serão armazenados usuário e senha, com tratamento conforme determina a Lei n.º 13.709/2018.
                    Seus dados não serão utilizados para conteúdo comercial, anúncios, ofertas ou qualquer mídia do gênero.
                    Assim como seus os acessos do aplicativo não serão rastreados.
                    Qualquer solicitação que você tenha interesse em fazer a respeito de seus dados, poderão ser atendidas por meio do 0800 970 0053.</p>
                <h2>Segurança</h2>
                <p>
                Importante esclarecer que a FLORENSE mantém rígido controle interno sobre o acesso aos seus dados, implementa mecanismos para garantir que não haja qualquer uso indevido de seus dados.

                <h2>Autoridade Pública</h2>
                <p>
                    A FLORENSE somente disponibilizará seus dados às Autoridades Públicas quando a requisição para tanto for feita nos moldes da legislação. Não efetuamos essa entrega de forma excessiva, ou quando não estiver em conformidade com as determinações legais.   
                </p>

                <h2>Cuidados com a Senha</h2>

                <p>Apesar dos esforços empreendidos pela Florense a fim de garantir a segurança dos seus dados, a utilização de serviços e o acesso a conteúdos da internet, envolve alguns riscos e exposições.</p>
                    Assim, é imprescindível que você também faça a sua parte, tomando as seguintes medidas que podem reduzir os riscos envolvidos:
                    
                    <ul>
                        <li>Não informe sua senha a terceiros.</li>
                        <li>Crie uma senha difícil de ser adivinhada, combinando letras, números e caracteres especiais.</li>
                        <li>Evite utilizar uma única senha para vários sites e não se esqueça de trocá-las periodicamente ou imediatamente em caso de suspeita que foi comprometida.</li>
                        <li>Use o botão “sair” ou “logoff”, após finalizar sua navegação.</li>
                        <li>Atenção redobrada ao acessar contas pessoais em computadores ou redes de acesso público.</li>
                        <li>Tenha sempre o sistema operacional e antivírus atualizado.</li>
                    </ul>

                    A senha é a primeira linha de defesa contra o acesso não autorizado. Quanto mais complexa ela for, mais forte a proteção contra ataques e violação da sua privacidade.
                    A FLORENSE utiliza tecnologias e procedimentos de segurança para proteger suas informações pessoais contra perdas, roubos e acesso, uso e alterações não autorizados, porém, é necessário alertar que nenhuma medida de segurança disponível no mercado é totalmente segura.
                    Por fim, informamos que para adaptar às expectativas dos usuários do aplicativo a FLORENSE poderá modificar a sua Política de Privacidade. Assim, é importante que você consulte nossa política regularmente.
                </p>
        </div>
        );
    }

}


export default PrivacyPoliticsPage;