import { setLocale } from "yup";

setLocale({
  mixed: {
    default: "Não é válido",
    notType: "Valor inválido",
    required: "Este campo precisa ser preenchido",
  },
  number: {
    required: "Este campo precisa ser preenchido",
  },
  string: {
    required: "Este campo precisa ser preenchido",
    min: "Este campo precisa ser maior que ${min}",
    max: "Este campo precisa ser me nor que ${max}"
  }
});

