import React from 'react';
import PropTypes from 'prop-types'
import PromptModal from 'components/Forms/PromptModal';
import {Input} from 'reactstrap'



class ActiveField extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            promptActive: false
        }
        this.handleAcceptPrompt = this.handleAcceptPrompt.bind(this);
        this.handleRefusePrompt = this.handleRefusePrompt.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
    }
    
    onCheckChange(){ 
        this.setState({promptActive: true}) 
    }
    
    handleRefusePrompt(){ 
        this.setState({promptActive: false}) 
    }

    handleAcceptPrompt(){
        this.setState({promptActive: false});
        this.props.handleAction();
    }

    
    render(){
        return(
            <React.Fragment>
                <PromptModal onRefuse={this.handleRefusePrompt}
                    onAccept={this.handleAcceptPrompt}
                    toggled={this.state.promptActive}
                    title={"Confirmação de alteração de Imagem"}
                >Você deseja mesmo Ativar/Desativar este registro?</PromptModal>
                <Input  type="checkbox"
                        className="mr-2" 
                        checked={this.props.value}
                        onChange={ this.onCheckChange}
                /> Ativo
            </React.Fragment>
        )
    }
}

ActiveField.propTypes = {
    value: PropTypes.bool.isRequired,
    handleAction: PropTypes.func.isRequired,
};

export default ActiveField;