
import React from 'react';
import MaterialTable from "material-table";
import {baseOptions, translations} from 'config/MaterialTable/config';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from 'store/actions/helpQuestions'
import Api from 'api/Api'
import {toast} from 'react-toastify';

class HelpQuestionsTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			actions:[
				{
            		isFreeAction: true,
					icon: 'add',
                    tooltip: 'Adicionar Texto de Ajuda',
                    onClick: () => {
                        this.props.actions.resetHelpQuestion()
                        this.props.history.push("/help/create") 
                    },
                    iconProps: {
                        className: "text-success icon-lg",
                    }
                },
                rowData => ({
                    icon: 'edit',
                    tooltip: 'Editar Ajuda',
                    onClick: this.edit,
                    iconProps: {
                        className: "text-warning"
                    }
                }),
                rowData => ({
                    icon: 'delete',
                    tooltip: 'Excluir Ajuda',
                    onClick: this.delete,
                    iconProps: {
                        className: "text-danger"
                    }
                })
            ]
        }
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
    }
    
    componentDidMount(){
        Api.HelpQuestions.all().then( (resp) => {   
            let {data} = resp;
            this.props.actions.setHelpQuestions(data);
        }).catch( (error) => {
            console.error(error);
            toast.error("Ops! Algo deu errado!");
        });
    }

    async delete(event,rowData){
        try {
            await Api.HelpQuestions.delete(rowData.id);
            toast.success("Ajuda deletada com sucesso");
            this.props.actions.removeHelpQuestion(rowData.id);
        }catch(error){
            toast.error("Erro ao deletar ajuda!")
            console.error(error);
        }
    }

    edit(event, rowData){
        this.props.actions.resetHelpQuestion();
		this.props.history.push('/help/update/' + rowData.id);	
    }
    
    render(){
        return(
            <div style={{ maxWidth: "100%" }}>
            <MaterialTable 
                columns={ columns } 
                title={ "Textos de Ajuda" }
                data={ this.props.helpQuestions }
                options={ baseOptions }
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                actions={ this.state.actions }
                localization={ translations }
            />
        </div>
        );
    }
};

const columns = [
    { title: "Título", field: "title_portuguese" },
    { title: "Título Espanhol", field: "title_english"},
    { title: "Título Inglês", field: "title_spanish"},
]

const mapStateToProps = (state) => {
    return {
         helpQuestions: state.helpQuestions.helpQuestions,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            setHelpQuestions: (helpQuestions) => {
                return dispatch({
                    type: actions.SET_HELP_QUESTIONS,
                    payload: {
                        helpQuestions: helpQuestions
                    }
                });
            },
            removeHelpQuestion: (id) => {
                return dispatch({
                    type: actions.REMOVE_ITEM_FROM_HELP_QUESTIONS,
                    payload:{
                        id: id
                    }
                })
            },
            resetHelpQuestion: () => {
                return dispatch({
                    type: actions.RESET_HELP_QUESTION
                })
            }

        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpQuestionsTable));