export const SET_COLOR = "COLORS_SET_COLOR";


export const TOGGLE_ACTIVE = "COLORS_TOGGLE_ACTIVE";
export const RESET_COLOR = "COLORS_RESET_COLOR";
export const RESET_COLORS = "COLORS_RESET_COLORS"
export const CHANGE_ATTR = "COLORS_CHANGE_ATTR";
export const SET_IMAGES = "COLORS_SET_IMAGES";
export const ADD_IMAGES = "COLORS_ADD_IMAGES";
export const RESET_IMAGES = "COLORS_RESET_IMAGES";

export const PUSH_IMAGE_ERROR = "COLORS_PUSH_IMAGE_ERROR";
export const TRIGGER_VALIDATION = "COLORS_TRIGGER_VALIDATION";
export const IS_COLOR_VALID = "COLORS_IS_COLOR_VALID";

// export const CHANGE_GEOMETRY = "COLORS_CHANGE_GEOMETRY";


export const CHANGE_IMAGE_ATTR = "COLORS_CHANGE_IMAGE_ATTR";
export const ADD_ERROR_IMAGE = "COLORS_ADD_ERROR_IMAGE";
export const SET_IMAGE_ERROR = "COLORS_SET_IMAGE_ERROR";

/**
 * Actions para lista de cores
 */
export const SET_COLORS = "COLORS_SET_COLORS";
export const SET_COLOR_LIST = "COLORS_SET_COLOR_LIST";
export const RESET_COLOR_LIST= "COLORS_RESET_COLOR_LIST";
export const CHANGE_ORIENTATION = "COLORS_CHANGE_ORIENTATION";
export const SET_ERROR = "COLORS_SET_ERROR";
export const SET_IMAGE = "COLORS_SET_IMAGE";
export const CHANGE_GEOMETRY = "COLORS_CHANGE_GEOMETRY";
export const RESET_STATE = "COLORS_RESET_STATE";
