import {API_URL, LOGS_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter, syncJsonFilter} from '../Filters';
import ApiBase from '../ApiBase'

class LogsApi extends ApiBase{
    
    constructor(){
        super()
        this.url = API_URL + LOGS_URL;
    }

    async all(){
        let params = {
            method: 'GET',
            headers: this.headers(),
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(syncJsonFilter)
        }catch(error){
            return {success: false, error: "erro"}
        }
        
    }
}

export default LogsApi;