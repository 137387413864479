import * as yup from 'yup'
import "config/yup/pt-BR/locale_pt-BR.js"

function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
  }

const finishesFormValidation = yup.object().shape({
    username: yup.string().ensure().required(),
    email: yup.string().ensure().email().required(),
    password:  yup.string().oneOf([yup.ref('password_confirmation'), null], 'Passwords must match').min(6),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').min(6),
});

export default finishesFormValidation;