
import React from 'react';
import MaterialTable from "material-table";
import {baseOptions, translations} from 'config/MaterialTable/config';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from 'store/actions/finishes'
import Api from 'api/Api'
import {toast} from 'react-toastify';
import ActiveIcon from 'components/ActiveIcon'
class FinishesTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			actions:[
				{
            		isFreeAction: true,
					icon: 'add',
                    tooltip: 'Adicionar Acabamento',
                    onClick: () => {
                        this.props.actions.resetFinish()
                        this.props.history.push("/finishes/create") 
                    },
                    iconProps: {
                        className: "text-success icon-lg",
                    }
				},
                rowData => ({
                    icon: 'edit',
                    tooltip: 'Editar Acabamento',
                    onClick: this.edit,
                    iconProps: {
                        className: "text-warning"
                    }
                })
            ]
        }
        this.edit = this.edit.bind(this);
    }
    
    componentDidMount(){
        Api.Finishes.all().then( (resp) => {
            let {data} = resp;
            this.props.actions.setFinishes(data);
        }).catch( (error) => {
            console.error(error);
            toast.error("Ops! Algo deu errado!");
        });
    }

    edit(event, rowData){
        this.props.actions.resetFinish();
		this.props.history.push('/finishes/update/' + rowData.id);	
    }
    
    render(){
        return(
            <div style={{ maxWidth: "100%" }}>
            <MaterialTable 
                columns={ columns } 
                title={ "Acabamentos" }
                data={ this.props.finishes }
                options={ baseOptions }
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                actions={ this.state.actions }
                localization={ translations }
            />
        </div>
        );
    }
};



const columns = [
	{ title: "Código", field: "code" },
    { title: "Descrição", field: "description_pt" },
    { title: "Sequência", field: "sequency"},
    { title: "Ativo", field: "active", render: (rowData) => <ActiveIcon value={rowData.active}></ActiveIcon>}
]

const mapStateToProps = (state) => {
    return {
         finishes: state.finishes.finishes,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
            actions: {
                resetFinishes: () => {
                    return dispatch({
                        type: actions.RESET_FINISHES
                    });
                },
                resetFinish: () => {
                    return dispatch({
                        type: actions.RESET_FINISH
                    });
                },
                setFinishes: (finishes) => {
                    return dispatch({
                        type: actions.SET_FINISHES,
                        payload: {
                            finishes: finishes
                        }
                    });
                }
            }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinishesTable));