import React from 'react';
import Api from 'api/Api';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {ErrorField} from "../"
import {Label} from "reactstrap" 
import "./style.scss"

class FinishesSelect extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            options: [],
            selectedValue: {}
        }   
        this.selectFromOptions = this.selectFromOptions.bind(this);
        this.getStyle = this.getStyle.bind(this)
    }
    
    getStyle(){
        if(this.props.valid){
            return {}
        }else{
            return {
                control: (provided, state) => ({ 
                    ...provided,
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "red"
                 })
            }
        }
    }

    selectFromOptions(id){
        let option = this.state.options.filter(option => option.value === id);
        return (option.length === 0 ) ? {} : option
    }

    componentDidMount(){
        Api.Finishes.all().then( (resp) => {
            let finishes = resp.data
            let options = []
            for(let finish of finishes){
                options.push({ value: finish.id, label: finish.description_pt})
            }
            let defaultValue = this.props.defaultValue;
            defaultValue = options.filter(option => option.value === defaultValue)
            defaultValue = (defaultValue.length === 0) ? {} : defaultValue[0]
            this.setState({
                options: options,
                selectedValue: defaultValue
            })
        })
    }

    render(){
        return(
            <React.Fragment>

                <Label>{this.props.label}</Label>
                <Select {...this.props} 
                        styles={this.getStyle()}
                        onChange={this.props.onChange}
                        value={this.selectFromOptions(this.props.selectedValue)}
                        options={this.state.options}>
                </Select>
                <ErrorField valid={this.props.valid}
                                    error={this.props.error}></ErrorField>
            </React.Fragment>
        );
    }
}

FinishesSelect.defaultProps = {
    selectedValue: ""
}

FinishesSelect.propTypes = {
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    valid: PropTypes.bool.isRequired,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default FinishesSelect;