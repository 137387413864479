import React from 'react';
// import { FormGroup, Input, FormFeedback, Label} from 'reactstrap'
import {connect} from 'react-redux'; 
import StringInput from "../StringInput"
import * as actions from "store/actions/colors"
import validation from '../../validation';
import * as yup from 'yup';

class DescriptionEnField extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            name: "description_en",
            invalid: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
   
    }

    handleChange(event){
        let value = event.target.value
        yup.reach(validation, this.state.name).validate(value, {abortEarly: false}).then( (resp) => {
            this.setState({invalid: false})
            this.props.actions.setError(this.state.name, "")
        }).catch( (err) => {
            for(let error of err.inner){
                this.setState({invalid: true})
                this.props.actions.setError(this.state.name, error.message)
            }
        })
        this.props.actions.change(this.state.name, value)

    }

    
    render(){
        return(
           <StringInput 
                label="Descrição em Inglês"
                value={this.props.description_en}
                name={this.state.name}
                placeholder="Descrição em Inglês"
                onChange={ this.handleChange}
                invalid={this.state.invalid}
                error={this.props.error}
                >
            </StringInput>
        );
    }
}
const mapStateToProps = (state) => {
    return {
         description_en: state.colors.color.description_en,
         error: state.colors.errors.description_en
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            change: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionEnField);