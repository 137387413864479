import React from 'react';
import { Form, Button, Row, Col} from 'reactstrap'
import ActiveCheckbox from './components/ActiveCheckbox';
import ColorCodeField from './components/ColorCodeField'
import DescriptionEnField from './components/DescriptionEnField';
import DescriptionEsField from './components/DescriptionEsField';
import DescriptionPtField from './components/DescriptionPtField';
import DetailedDescriptionEnField from './components/DetailedDescriptionEnField';
import DetailedDescriptionEsField from './components/DetailedDescriptionEsField';
import DetailedDescriptionPtField from './components/DetailedDescriptionPtField';
import FinishField from './components/FinishField'
import GeometryField from './components/GeometryField';
import ImageFinalDateField from './components/ImageFinalDateField';
import ImageInitialDateField from './components/ImageInitialDateField';
import ImageOrientationField from './components/ImageOrientationField';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'; 
import * as actions from 'store/actions/colors';
import validation from './validation';
import Api from 'api/Api'
import {toast} from 'react-toastify'
import HorizontalImageInput from './components/HorizontalImageInput';
import HorizontalWithShadowImageInput from './components/HorizontalWithShadowImageInput ';
import VerticalWithShadowImageInput from './components/VerticalWithShadowImageInput';
import VerticalImageInput from './components/VerticalImageInput'
class ColorAndImagesForm extends React.Component {

    constructor(props){
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.processColorJson = this.processColorJson.bind(this);
    }

    /**
     * monta o json final para envio 
     */
    
    processColorJson(){
        let {color, images} = this.props;
        /**
         * Marcar para destruicao, ou remover todas imagens disabled
         */
        let final_images =[]
        for(let im in images){
            let image = images[im]
            if(image.enabled === false && image.id !== null){
                final_images.push({
                    id: image.id,
                    _destroy: true
                })
            }else if(image.enabled === true){
                delete image['enabled']
                delete image['url']
                image.geometry = color.geometry
                final_images.push(image)
            }
        }
        return {
            ...color, 
            images: final_images
        }
    }

    async handleSubmit(event){
        event.preventDefault();
        await validation.validate(this.props.color, {abortEarly: false}).then( (valid) => {
            let data = this.processColorJson()
            let {id} = this.props.match.params;
            if(id === undefined){
                Api.Colors.create(data).then(resp => {
                    toast.success("Cor Criada com sucesso")
                }).catch(error => {
                    toast.error("Erro ao criar cor, verifique os dados informados")
                    console.error(error);
                });
            }else{
                // data.images = color.images.filter( (image) => image.display === false).map(image => image_params(image))
                Api.Colors.update(id, data).then( resp => {
                    toast.success("Cor alterada com sucesso")
                }).catch(error => {
                    toast.error("Erro ao editar cor, verifique os dados informados")
                    console.error(error);
                })
            }
        }).catch( err => {
            console.error(err);
            for(let error of err.inner){
                this.props.actions.setError(error.path, error.message)
            }
        }) 
        this.props.actions.resetForm();
        this.props.history.push("/colors");
    }
    
    componentDidMount(){
        let {id} = this.props.match.params
        this.props.actions.resetForm()
        if(id === undefined){
            return
        }else{
            Api.Colors.get(id).then( (resp) => {
                let color = resp.data;
                console.log(this.props.images);
                this.props.actions.setColor(color);
                console.log(this.props.images);
            }).catch( (err) => { 
                console.error(err);
                toast.error("Não pode buscar Cor!");
            })
        }
    }

    
    render(){
        return(
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col xs="12">
                        <h2>Manutenção de Cores</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <ColorCodeField></ColorCodeField>
                    </Col>
                    <Col xs="4">
                        <FinishField></FinishField>  
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <DescriptionPtField></DescriptionPtField>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <DescriptionEnField></DescriptionEnField>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <DescriptionEsField></DescriptionEsField>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <DetailedDescriptionPtField></DetailedDescriptionPtField>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <DetailedDescriptionEnField></DetailedDescriptionEnField>
                    </Col>
                </Row>
                
                <Row>
                    <Col xs="6">
                        <DetailedDescriptionEsField></DetailedDescriptionEsField>
                    </Col>
                </Row>

                <hr></hr>
                <Row>
                    <Col xs="4">
                        <ImageInitialDateField></ImageInitialDateField>
                    </Col>
                    <Col xs="4">
                        <ImageFinalDateField></ImageFinalDateField>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-4" xs="12">
                        <ActiveCheckbox ></ActiveCheckbox>
                    </Col>
                </Row>
                {/* <ColorsForm /> */}
                <hr></hr>
                <Row>
                    <Col xs="4">
                        <ImageOrientationField></ImageOrientationField>
                    </Col>

                    <Col xs="4">
                        <GeometryField 
                            isDisabled={this.props.color.image_orientation === ""}></GeometryField>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                
                <Row>
                    <Col xs="6" className={this.props.horizontal_with_shadow_visible ? "" : "d-none"}>
                        <HorizontalWithShadowImageInput></HorizontalWithShadowImageInput>
                    </Col>
                    <Col xs="6" className={this.props.horizontal_visible ? "" : "d-none"}>
                        <HorizontalImageInput></HorizontalImageInput>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6" className={this.props.vertical_with_shadow_visible ? "" : "d-none"}>
                        <VerticalWithShadowImageInput></VerticalWithShadowImageInput>
                    </Col>
                    <Col xs="6" className={this.props.vertical_visible ? "" : "d-none"}>
                        <VerticalImageInput></VerticalImageInput>
                    </Col>
                </Row>
                
                {/* <ImagesForm /> */}
                <Row className="mt-4">
                    <Col xs="12">
                        <Button color="success" >Enviar</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        color: state.colors.color,
        images: state.colors.images,
        vertical_visible: state.colors.images.vertical.enabled,
        vertical_with_shadow_visible: state.colors.images.vertical_with_shadow.enabled,
        horizontal_visible: state.colors.images.horizontal.enabled,
        horizontal_with_shadow_visible: state.colors.images.horizontal_with_shadow.enabled
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            resetForm: () => {
                return dispatch({
                    type: actions.RESET_STATE,
                })
            },
            setColor: (data) => {
                return dispatch({
                    type: actions.SET_COLOR,
                    payload: {
                        data: data
                    }
                })
            }
        }
    }
}
// export default ColorAndImagesForm;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ColorAndImagesForm));