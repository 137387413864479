import React, { Component } from 'react';
import {Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import logo from 'assets/img/brand/logo_florense.png';

class PasswordResetSuccessfully extends Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                            <h1>Senha Alterada com Sucesso</h1>
                                            <p className="text-muted">
                                                Sua senha foi alterada com sucesso, você ja pode efetuar login com sua nova senha.
                                            </p>
                                    </CardBody>
                                </Card>
                                <Card className="text-white py-5 d-md-down-none" style={{ backgroundColor: "black", width: '44%' }}>
                                    <CardBody className="text-center">
                                        {/* FLORENSE */}
                                        <div>
                                            <img src={logo} className="" alt="admin@florense.com.br" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default PasswordResetSuccessfully;
