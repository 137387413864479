
import * as actionTypes from 'store/actions/auth';

const initialState = {
    auth: {
        username: "",
        password: ""
    }
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.SET_ERROR:
            return {
                ...state,
                auth: {
                    ...state.auth, 
                    error: {
                        ...state.auth.error,
                        [action.payload.name]: action.payload.error
                    },
                    valid: {
                        ...state.auth.valid,
                        [action.payload.name]: (action.payload.error.length === 0 ? true : false)
                    }
                }
            }
        case actionTypes.CHANGE_ATTR:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    [action.payload.name]: action.payload.value
                }
            }
        default:
            return state;
    }
}
export default reducer;