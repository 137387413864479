import * as yup from 'yup'
import "config/yup/pt-BR/locale_pt-BR.js"

const helpFormValidation = yup.object().shape({
	title_english: yup.string().required(),
    title_spanish: yup.string().required(),
    title_portuguese: yup.string().required(),
    body_english: yup.string().required(),
    body_spanish: yup.string().required(),
    body_portuguese: yup.string().required()
});

export default helpFormValidation;