import {store} from 'store/store';


class ApiBase{

    constructor(){
        this.store = store;
    }
    
    getAuth(){
        let state = this.store.getState()
        return state.users.auth
    }

    headers(){
        let auth = this.getAuth();
        let headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'access-token': auth.access_token,
            client: auth.client,
            uid: auth.uid,       
        }
        return headers
    }
}



export default ApiBase;