

export const imageFormatOptions = [
    {label: "Quadrado", value: "squared"},
    {label: "Retângulo", value: "rectangular"},
]


export const imageOrientationOptions = [
    { label: 'Horizontal', value: "horizontal"},
    { label: 'Vertical', value: "vertical"},
    { label: 'Horizontal e Vertical', value: "both"}
]

export const imageGeometryOptions = [
    { label: "Quadrada", value: "squared"},
    { label: "Retangular", value: "rectangular"}
]