import React from 'react';
import { FormGroup, Input, FormFeedback, Label} from 'reactstrap'
import PropTypes from 'prop-types';
class StringInput extends React.Component {

    constructor(props){
        super(props)
    }

    componentDidMount(){
    }

    
    render(){
        return(
            <FormGroup>
                <Label for="">{this.props.label}</Label>
                <Input  type={this.props.type || "text"} 
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        invalid={this.props.error == "" ? false : true}></Input>
                <FormFeedback > {this.props.error}</FormFeedback>
            </FormGroup>
        );
    }
}

StringInput.defaultProps = {
    invalid: false
};

StringInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired
};

export default StringInput;
