import React from 'react';
// import { FormGroup, Input, FormFeedback, Label} from 'reactstrap'
import {connect} from 'react-redux'; 
import * as actions from "store/actions/colors";
import {ImageField} from 'components/Forms';

class HorizontalWithShadowImageInput extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            name: "horizontal_with_shadow",
            invalid: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.horizontalWithShadowFileRef = React.createRef();
    }

    componentDidMount(){
   
    }

    handleChange(file){
        this.props.actions.change(this.state.name, file)
    }

    
    render(){
        return(
            <React.Fragment>
            <ImageField
                    title={"Imagem horizontal para o Mood"}
                    fileRef={this.horizontalWithShadowFileRef}
                    inputId={this.state.name}
                    onChange={this.handleChange}
                    url={this.props.image.url}
                    onChange={this.handleChange}>
                </ImageField>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        image: state.colors.images.horizontal_with_shadow,
        error: state.colors.errors.horizontal_with_shadow
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            change: (name, value) => {
                return dispatch({
                    type: actions.SET_IMAGE,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalWithShadowImageInput);