
import * as actionTypes from 'store/actions/termsOfUse';

const initialState = {
    termsOfUses: [],
    termsOfUse: {
        body: '',
        title: '',
        body_english: '',
        body_spanish: '',
        error: {
            body: '',
            title: '',
            body_english: '',
            body_spanish: ''
        },
        valid: {
            body: true, 
            title: true,
            body_english: true,
            body_spanish: true
        }
    }
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.RESET_TERMS_OF_USE_FORM:
            return {
                ...state,
                termsOfUse: {
                    ...initialState.termsOfUse,
                }
            }
        case actionTypes.SET_TERMS_OF_USES:
            return {
                ...state,
                termsOfUses: action.payload.termsOfUses,
            }
        case actionTypes.CHANGE_ATTR:
            return {
                ...state,
                termsOfUse: {
                    ...state.termsOfUse,
                    [action.payload.name]: action.payload.value    
                }
            }
        case actionTypes.SET_TERMS_OF_USE: 
            return {
                ...state,
                termsOfUse: {
                    ...state.termsOfUse,
                    id: action.payload.termsOfUse.id,
                    title: action.payload.termsOfUse.title,
                    body: action.payload.termsOfUse.body,
                    language: action.payload.termsOfUse.language,
                    body_english: action.payload.termsOfUse.body_english,
                    body_spanish: action.payload.termsOfUse.body_spanish
                }
            }
        case actionTypes.SET_ERROR:
            return {
                ...state,
                termsOfUse: {
                    ...state.termsOfUse, 
                    error: {
                        ...state.termsOfUse.error,
                        [action.payload.name]: action.payload.error
                    },
                    valid: {
                        ...state.termsOfUse.valid,
                        [action.payload.name]: (action.payload.error.length === 0 ? true : false)
                    }
                }
            }
        default:
            return state;
    }
}

export default reducer;