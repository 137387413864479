import React from 'react';
import {connect} from 'react-redux';
import StringInput from "../StringInput"
import * as actions from "store/actions/colors"
import validation from '../../validation';
import * as yup from 'yup';
import FinishSelect from 'components/Forms/FinishesSelect';

class FinishField extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            name: "finish_id",
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
   
    }

    handleChange(selectedValue, event){
        let {value} = selectedValue
        this.props.actions.change(this.state.name, value);
        yup.reach(validation, this.state.name).validate(value).then( (resp) => {
            this.props.actions.setError(this.state.name, "");
        }).catch( (err) => {
            this.props.actions.setError(this.state.name, err.errors);
        })
        this.props.actions.change(this.state.name, value)
    }

    
    render(){
        return(
            <React.Fragment>
                <FinishSelect
                    label="Acabamento"
                    onChange={this.handleChange}
                    selectedValue={this.props.finish_id}
                    valid={this.props.error == "" ? true : false}
                    error={this.props.error}
                    ></FinishSelect>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
         finish_id: state.colors.color.finish_id,
         error: state.colors.errors.finish_id
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            change: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishField);