import React from 'react';
import UsersTable from './components/UsersTable'
class UsersIndex extends React.Component {

    render(){
        return(
            <UsersTable/>
        );
    }
}

export default UsersIndex;