import React from 'react'
import {Form, FormFeedback, Input, Label, Row, Col, FormGroup} from 'reactstrap';
import * as actions from 'store/actions/helpQuestions';
import {connect} from 'react-redux';
import {ButtonWithLoading} from 'components/Forms';
import Api from 'api/Api';
import {toast} from 'react-toastify';
import validation from './validation';
import * as yup from 'yup';
import {withRouter} from 'react-router-dom';

/**
 * Css
 */
import 'react-quill/dist/quill.snow.css';
class HelpQuestionsForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false
        }
        this.submit = this.submit.bind(this);
        this.handleValidateAndSubmit = this.handleValidateAndSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.findLanguage = this.findLanguage.bind(this);
    }

    findLanguage(lang){
        return this.state.languageOptions.find( (v) => {
           return v.value == lang
        })
    }

    componentDidMount(){
        let {id} = this.props.match.params
        if(id === undefined){
            console.log(id);
            return
        }else{
            Api.HelpQuestions.get(id).then( (resp) => {
                let help = resp.data
                this.props.actions.setHelp(help);
            }).catch( (err) => {
                console.log(err);
                toast.error("Não pode buscar texto de ajuda!");
            })    
        }   
    }

    handleChange(event){
        const value = event.target.value;
        const name = event.target.name;
        this.props.actions.changeAttr(name, value);
        
        yup.reach(validation, name).validate(value).then( (resp) => {
            this.props.actions.setError(name, "");
        }).catch( (err) => {
            this.props.actions.setError(name, err.errors);
        })
    }

    async submit(){
        let {help} = this.props;
        try{
            if(help.id !== "" && help.id !== undefined){
                await Api.HelpQuestions.update(help.id, help)
            }else{
                await Api.HelpQuestions.create(help)
            }
            toast.success("Ajuda salvo com sucesso!");
            this.props.history.push("/help");
        }catch(error){
            console.log(error);
            toast.error("Ops! Algo deu errado!")
        }
    }

    handleValidateAndSubmit(event){
        event.preventDefault();
        this.setState({loadingSubmit: true});
        validation.validate(this.props.help, { abortEarly: false})
            .then( (resp) => {
                this.submit();
            }).catch( (err) => {
                for( let error of err.inner){
                    this.props.actions.setError(error.path, error.message);
                }
                this.setState({loadingSubmit: false});
                toast.error("Por favor, verifique os dados informados!");
            })
        
    }

    render(){
        let {help} = this.props;
        return(
            <div>
                <Form onSubmit={this.handleValidateAndSubmit}>
                    <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="title_portuguese">Título em Português</Label>
                                <Input  name="title_portuguese" id="title_portuguese"
                                        onChange={this.handleChange}
                                        value={help.title_portuguese}
                                        invalid={!help.valid.title_portuguese}
                                        ></Input>
                                <FormFeedback >{(help.error.title_portuguese.length > 0) ? 
                                                        help.error.title_portuguese : ''}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="body">Texto em Português</Label>
                                <Input type="textarea"
                                        onChange={this.handleChange}
                                        name="body_portuguese"
                                        invalid={!help.valid.body_portuguese}   
                                        value={help.body_portuguese} 
                                        id="body_portuguese" />
                                <FormFeedback >{(help.error.body_portuguese.length > 0) ? 
                                                    help.error.body_portuguese : ''}
                                </FormFeedback>  
                            </FormGroup>
                        </Col>
                    </Row> 
                     <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="title_english">Título em Inglês</Label>
                                <Input  name="title_english" id="title_english"
                                        onChange={this.handleChange}
                                        value={help.title_english}
                                        invalid={!help.valid.title_english}
                                        ></Input>
                                <FormFeedback >{(help.error.title_english.length > 0) ? 
                                                        help.error.title_english : ''}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="body">Texto em Inglês</Label>
                                <Input type="textarea"
                                        onChange={this.handleChange}
                                        name="body_english"
                                        invalid={!help.valid.body_english}  
                                        value={help.body_english} 
                                        id="body_english" />
                                <FormFeedback >{(help.error.body_english.length > 0) ? 
                                                    help.error.body_english : ''}
                                </FormFeedback>  
                            </FormGroup>
                        </Col>
                    </Row>
                     <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="title_spanish">Título em Espanhol</Label>
                                <Input  name="title_spanish" id="title_spanish"
                                        onChange={this.handleChange}
                                        value={help.title_spanish}
                                        invalid={!help.valid.title_spanish}
                                        ></Input>
                                <FormFeedback >{(help.error.title_spanish.length > 0) ? 
                                                        help.error.title_spanish : ''}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="body">Texto em Espanhol</Label>
                                <Input type="textarea"
                                        onChange={this.handleChange}
                                        name="body_spanish" 
                                        value={help.body_spanish}
                                        invalid={!help.valid.body_spanish} 
                                        id="body_spanish" />
                                <FormFeedback >{(help.error.body_spanish.length > 0) ? 
                                                    help.error.body_spanish : ''}
                                </FormFeedback>  
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
                            <ButtonWithLoading loading={this.state.loadingSubmit} type="submit"
                                           value="submit" className="col-md-2 mr-2" color="success">
                                Salvar
                            </ButtonWithLoading>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        help: state.helpQuestions.helpForm,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            setHelp: (help) => {
                help.id = (help.id == null) ? "" : help.id
                help.title_english = (help.title_english === null) ? "" : help.title_english
                help.title_spanish = (help.title_spanish === null) ? "" : help.title_spanish
                help.title_portuguese = (help.title_portuguese === null) ? "" : help.title_portuguese
                help.body_english = (help.body_english === null) ? "" : help.body_english
                help.body_spanish = (help.body_spanish === null) ? "" : help.body_spanish
                help.body_portuguese = (help.body_portuguese === null) ? "" : help.body_portuguese
                return dispatch({
                    type: actions.SET_HELP,
                    payload: {
                        help: help
                    }
                });
            },
            setError: (name, error) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
            },
            changeAttr: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name, 
                        value: value
                    }
                });
            }
        }
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpQuestionsForm));