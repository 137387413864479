export const convertFileToBase64  = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve({
            filename: blob.name,
            file: reader.result
        });
        reader.onerror = error => reject(error);
    });
}