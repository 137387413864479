
import React from 'react' 
import {Modal, ModalBody, ModalFooter, Button} from 'reactstrap'
import PropTypes from 'prop-types';
class PromptModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            opened: false
        }
        this.toggleModal = this.toggleModal.bind(this);
    }


    toggleModal() {
        this.setState({ opened: !this.state.opened });
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.toggled !== prevProps.toggled) {
         
        }
    }

    render(){
        return(
            <Modal isOpen={this.props.toggled} toggle={this.toggleModal}
                className={'modal-sm ' + this.props.className}>
                {/* <ModalHeader >{this.props.title}</ModalHeader> */}
                        <ModalBody>
                            {this.props.children}
                        </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.onAccept}>
                        Confirmar
                    </Button>
                    <Button color="secondary" onClick={this.props.onRefuse}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

PromptModal.propTypes = {
    onRefuse: PropTypes.func,
    onAccept: PropTypes.func,
    isOpen: PropTypes.bool
};

export default PromptModal