import React from 'react';
import { Form, FormFeedback, FormGroup, Label, Input, Button, Row, Col} from 'reactstrap'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'; 
import * as actions from 'store/actions/finishes';
import validation from './validation';
import * as yup from 'yup';
import Api from 'api/Api';
import {ImageField} from 'components/Forms';
import {toast} from 'react-toastify';
import ActiveField from 'components/Forms/ActiveField'
class FinishesForm extends React.Component {


    constructor(props){
        super(props);
        this.state = {
            url: ""
        }
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.file = React.createRef();
        this.submit = this.submit.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.activeField = this.activeField.bind(this);
        this.handleSequencyChange = this.handleSequencyChange.bind(this);
    }

    activeField(){
        let {id} = this.props.match.params
        let {finish} = this.props;
        if(id !== undefined){
            return(<Row>
                <Col xs="12">
                    <FormGroup check>
                        <Label check>
                            <ActiveField value={finish.active} handleAction={this.props.actions.toggleActive}></ActiveField>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>)
        }
        return(null)
    }

    handleImageChange(image){
        yup.reach(validation, "image").validate(image).then( (resp) => {
            this.props.actions.changeAttr("image", image);
            this.props.actions.setError("image", "");
        }).catch( (err) => {
            this.props.actions.setError("image", err.errors);
        })
    }

    componentDidMount(){
        let {id} = this.props.match.params
        if(id === undefined){
            return
        }else{
            Api.Finishes.get(id).then( (resp) => {
                let finish = resp.data;
                this.props.actions.setFinish(finish);
                this.setState({url: finish.image})
            }).catch( (err) => {
                toast.error("Não pode buscar Acabamento!");
            })
        }
    }

    handleSequencyChange(event){
        const value = event.target.value;
        if(value > 999){
            return;
        }
       this.handleChange(event)
    }

    async submit(){ 
        let {error, valid, ...finish} = this.props.finish;
        try{
            if(finish.id !== "" && finish.id !== undefined){
                await Api.Finishes.update(finish.id, finish)
            }else{
                await Api.Finishes.create(finish);
            }
            toast.success("Acabamento salvo com sucesso!");
            this.props.actions.resetFinish();
            this.props.history.push("/finishes");
        }catch(error){
            console.error(error);
            for(let prop in error){
                this.props.actions.setError(prop, error[prop])
            }
            toast.error("Ops! Algo deu errado!")
        }
    }

    handleChange(event){
        const value = event.target.value;
        const name = event.target.name;
        this.props.actions.changeAttr(name, value);
        yup.reach(validation, name).validate(value).then( (resp) => {
            this.props.actions.setError(name, "");
        }).catch( (err) => {
            this.props.actions.setError(name, err.errors);
        })
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loadingSubmit: true});
        console.log(this.props.finish);
        validation.validate(this.props.finish, { abortEarly: false}).then( (resp) => {
            this.submit();
        }).catch( (err) => {
            console.error(err)
            for( let error of err.inner){
                this.props.actions.setError(error.path, error.message);
            }
            this.setState({loadingSubmit: false});
            toast.error("Por favor, verifique os dados informados!");
        })
    }
    
    handleCheckChange(value){
        this.props.actions.toggleActive()
    }

    render(){
        let {finish} = this.props
        return(
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col xs="12">
                        <h2> Manutenção de Acabamento</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for="code">Código</Label>
                            <Input  name="code" id="code"
                                    onChange={this.handleChange}
                                    value={finish.code}
                                    invalid={!finish.valid.code}
                                    ></Input>
                            <FormFeedback >{(finish.error.code.length > 0) ? 
                                                    finish.error.code : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for="sequency">Sequência no Menu</Label>
                            <Input  name="sequency" id="sequency"
                                    onChange={this.handleSequencyChange}
                                    value={finish.sequency}
                                    invalid={!finish.valid.sequency} />
                            <FormFeedback >{(finish.error.sequency.length > 0) ? 
                                                    finish.error.sequency : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="description_pt">Título em Português</Label>
                            <Input  name="description_pt" id="description_pt"
                                    onChange={this.handleChange}
                                    value={finish.description_pt}
                                    invalid={!finish.valid.description_pt}
                                    ></Input>
                            <FormFeedback >{(finish.error.description_pt.length > 0) ? 
                                                    finish.error.description_pt : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="description_en">Título em Inglês</Label>
                            <Input  name="description_en" id="description_en"
                                    onChange={this.handleChange}
                                    value={finish.description_en}
                                    invalid={!finish.valid.description_en}
                                    ></Input>
                            <FormFeedback >{(finish.error.description_en.length > 0) ? 
                                                    finish.error.description_en : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="description_es">Título em Espanhol</Label>
                            <Input  name="description_es" id="description_es"
                                    onChange={this.handleChange}
                                    value={finish.description_es}
                                    invalid={!finish.valid.description_es}
                                    ></Input>
                            <FormFeedback >{(finish.error.description_es.length > 0) ? 
                                                    finish.error.description_es : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                <Row>
                    <Col xs="4">
                        <ImageField fileRef={this.file} 
                                    url={this.state.url}
                                    onChange={ this.handleImageChange}>

                        </ImageField>
                    </Col>
                </Row>
                { this.activeField()}
                <Row className="mt-4">
                    <Col xs="12">
                        <Button color="success">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            resetFinish: () => {
                return dispatch({
                    type: actions.RESET_FINISH
                });
            },
            toggleActive: () => {
                return dispatch({
                    type: actions.TOGGLE_ACTIVE
                })
            },
            setFinish: (finish) => {
                finish.id = (finish.id === null) ? "" : finish.id
                finish.description_pt = (finish.description_pt === null) ? "" : finish.description_pt
                finish.description_es = (finish.description_es === null) ? "" : finish.description_es
                finish.description_en = (finish.description_en === null) ? "" : finish.description_en
                finish.code = (finish.code === null) ? "" : finish.code
                return dispatch({
                    type: actions.SET_FINISH,
                    payload: {
                        finish: {
                            ...finish,
                            image: {
                                url: finish.image
                            }
                        }
                    }
                });
            },
            setError: (name, error) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
            },
            changeAttr: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name, 
                        value: value
                    }
                });
            }
        }
    };
};

const mapStateToProps = (state) => {
    return {
        token: state.users.auth.token,
        finish: state.finishes.finish,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinishesForm));