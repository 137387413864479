
import * as actionTypes from 'store/actions/helpQuestions';

const initialState = {
    helpQuestions: [],
    helpForm: {
        title_english: '',
        title_spanish: '',
        title_portuguese: '',
        body_english: '',
        body_spanish: '',
        body_portuguese: '',
        error: {
            title_english: '',
            title_spanish: '',
            title_portuguese: '',
            body_english: '',
            body_spanish: '',
            body_portuguese: ''
        },
        valid: {
            title_english: true,
            title_spanish: true,
            title_portuguese: true,
            body_english: true,
            body_spanish: true,
            body_portuguese: true
        }
    }
}


const removeHelpQuestion = (id, state = initialState) => {
    let { helpQuestions } = state;
    return helpQuestions.filter( (value, index, arr) => {
        return value.id !== id
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.REMOVE_ITEM_FROM_HELP_QUESTIONS: 
            return {
                
                ...state,
                helpQuestions: removeHelpQuestion(action.payload.id, state)
            };
        case actionTypes.SET_HELP_QUESTIONS: {
            return {
                ...state,
                helpQuestions: action.payload.helpQuestions,
            }
        }
        case actionTypes.CHANGE_ATTR:
            return {
                ...state,
                helpForm: {
                    ...state.helpForm,
                    [action.payload.name]: action.payload.value    
                }
            };
        case actionTypes.RESET_HELP_QUESTION:
            return {
                ...state,
                helpForm: {
                    ...initialState.helpForm
                }
            }
        case actionTypes.SET_HELP: 
            return {
                ...initialState,
                helpForm: {
                    ...initialState.helpForm,
                    id: action.payload.help.id,
                    title_english: action.payload.help.title_english,
                    title_spanish: action.payload.help.title_spanish,
                    title_portuguese: action.payload.help.title_portuguese,
                    body_english: action.payload.help.body_english,
                    body_spanish: action.payload.help.body_spanish,
                    body_portuguese: action.payload.help.body_portuguese,
                }
            }
        case actionTypes.SET_ERROR:
            return {
                ...state,
                helpForm: {
                    ...state.helpForm, 
                    error: {
                        ...state.helpForm.error,
                        [action.payload.name]: action.payload.error
                    },
                    valid: {
                        ...state.helpForm.valid,
                        [action.payload.name]: (action.payload.error.length === 0 ? true : false)
                    }
                }
            }
        default:
            return state;
    }
}

export default reducer;