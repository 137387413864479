import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout/Layout'
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import './index.css'
import Page404 from './pages/Pages/Page404'
// import Login from './pages/Pages/Login'
import Login from './pages/Authentication/LoginForm';
import ForgotPasswordForm from 'pages/Authentication/ForgotPasswordForm';
import ResetPasswordForm  from 'pages/Authentication/ResetPasswordForm';
import HelpPage from 'pages/HelpPage'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from 'components/Layout/components/ErrorBoundary'
import TermosDeUsoPage from 'pages/TermosDeUsoPage'
import PrivacyPoliticsPage from 'pages/PrivacyPoliticsPage'
import PasswordResetSuccessfully from  'pages/Pages/PasswordResetSuccessfully';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


// Containers
// const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
// const Login = React.lazy(() => import('./pages/Pages/Login'));
// const Register = React.lazy(()  => import('./views/Pages/Register'));
// const Page404 = React.lazy(() => import('./pages/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  render() {
    return (
      <ErrorBoundary>
        <React.Fragment>
          <ToastContainer/>
          <BrowserRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route path="/ajuda" name="Ajuda" render = { props => <HelpPage {...props}/>}/>
                  <Route path="/termos-de-uso" name="Termos de Uso" render = { props => <TermosDeUsoPage {...props}/>}/>
                  <Route path="/politica-de-privacidade" name="Politica de Privacidade" render= { props => <PrivacyPoliticsPage {...props}/>} />
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/password" name="Esqueci Minha Senha" render = {props => <ForgotPasswordForm/>}/>
                  <Route exact path="/reset_password" name="Resetar Senha" render = {props => <ResetPasswordForm /> }/>
                  <Route exact path="/password_changed" name="Senha alterada com sucesso" render = {props => <PasswordResetSuccessfully /> }/>
                  <Route path="/" name="Home" render = { props => <Layout {...props}/>} />
                  {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> */}
                </Switch>
              </React.Suspense>
          </BrowserRouter>
        </React.Fragment>
      </ErrorBoundary>
    );
  }
}

export default App;

/**
 * 
 */