
import React from 'react';
import MaterialTable from "material-table";
import {baseOptions, translations} from 'config/MaterialTable/config';
import Api from 'api/Api'
import {toast} from 'react-toastify';
class LogsTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            logs: []
        }
    }
    
    componentDidMount(){
        Api.Logs.all().then( (resp) => {
            let {data} = resp;
            this.setState({logs: data})
        }).catch( (error) => {
            console.error(error);
            toast.error("Ops! Algo deu errado!");
        });
    }

    
    render(){
        return(
            <div style={{ maxWidth: "100%" }}>
            <MaterialTable 
                columns={ columns } 
                title={ "Logs" }
                data={ this.state.logs }
                options={ baseOptions }
                isLoading={this.state.loading}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                actions={ this.state.actions }
                localization={ translations }
            />
        </div>
        );
    }
};



const columns = [
    { title: "Id", field: "id" },
    { title: "Registro", field: "model"},
    { title: "Transação", field: "transaction_type" },
    { title: "Data", field: "created_at"},
]

export default LogsTable
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogsTable));