import {API_URL, COLORS_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter} from '../Filters';
import ApiBase from '../ApiBase';

class ColorsApi extends ApiBase{
    
    constructor(){
        super();
        this.url = API_URL + COLORS_URL;
    }

    async get(id){
        let params = {
            method: 'GET',
            headers: this.headers(),
        }
        let url = this.url +"/" + id;
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }catch(error){
            return {success: false, error: "erro"}
        }
    }

    async all(){
        let params = {
            method: 'GET',
            headers: this.headers(),
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw new Error({success: false, error: "erro"}); 
        }
    }

    async update(id, data){
        let params = {
            method: 'PATCH',
            headers: this.headers(),
            body: JSON.stringify({color: data})
        }
        let url = this.url + "/" + id;
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw new Error({success: false, error: "erro"}); 
        }
    }

    async create(data){
        let params = {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify({color: data})
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw new Error({success: false, error: "erro"}); 
        }
    }
}

export default ColorsApi;