import React, { Component } from 'react';
import {Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {ButtonWithLoading} from 'components/Forms';
import logo from 'assets/img/brand/logo_florense.png';
import {Redirect, withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux'; 
import * as userActions from 'store/actions/users'
import * as actions from 'store/actions/auth';
import Api from 'api/Api';
import { toast } from 'react-toastify';
import Helpers from 'util/helpers'
class Login extends Component {

    constructor(props){
        super(props);
        this.state = {
            authenticated: false,
            username: "",
            password: "",
            loading: false,
            checkingCredentials: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount(){
        this.setState({checkingCredentials: true})
        try{
            let resp = await Helpers.UserHelper.isAuthenticated()
            if(resp){
                this.props.history.push("/")
                return
            }
            this.setState({checkingCredentials: false})
        }catch(error){
            console.error(error);
            this.setState({checkingCredentials: false})
        }
    }
    
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loading: true})
        Api.Authentication.login(this.state.username, this.state.password).then(resp => {
            if(resp.data.role === "user"){
                this.setState({loading: false})
                toast.error("Acesso Negado")
                return false;  
            }
            this.props.actions.setCredentials({
                username: resp.data.username,
                role: resp.data.role, 
                ...resp.data.token})
            this.setState({loading: false})
            toast.success("Login feito com sucesso");
            this.props.history.push("/");
        }).catch( error => {
            console.error(error);   
            toast.error(error.errors[0])
            this.setState({loading: false})
        });
    }

    render() {
        if(this.state.checkingCredentials){
            return <div/>
        }
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <h1>Login</h1>
                                            <p className="text-muted">Por favor, digite seu usuário e senha para fazer login!</p>

                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-user"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" name="username" placeholder="Username" autoComplete="username"             
                                                            onChange={this.handleChange}
                                                            value={this.state.username}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" name="password"  value={this.state.password}
                                                        onChange={this.handleChange} placeholder="Password" 
                                                        autoComplete="current-password" />
                                            </InputGroup>
                                            <Row>
                                                <Col xs="5">
                                                    <ButtonWithLoading outline loading={this.state.loading} color="primary outline" className="col-md-12 px-4">Login</ButtonWithLoading>
                                                </Col>
                                                <Col xs="7" className="text-right">
                                                    <Link to='/password'> Esqueceu sua senha?</Link>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                                <Card className="text-white py-5 d-md-down-none" style={{ backgroundColor: "black", width: '44%' }}>
                                    <CardBody className="text-center">
                                        {/* FLORENSE */}
                                        <div>
                                            <img src={logo} className="" alt="admin@florense.com.br" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}




const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            setCredentials: (credentials) => {
                return dispatch({
                    type: userActions.SET_CREDENTIALS,
                    payload: {
                        credentials: credentials
                    }
                })
            },
            setError: (name, error) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
            },
            changeAttr: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name, 
                        value: value
                    }
                });
            }
        }
    };
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
