
import {API_URL, SETTINGS_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter} from '../Filters';
import ApiBase from '../ApiBase';
class SettingsApi extends ApiBase {
    
    constructor(){
        super()
        this.url = API_URL + SETTINGS_URL;
    }

    async all(){
        let params = {
            method: 'GET',
            headers: this.headers(),
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(jsonFilter)
        }catch(error){
            return {success: false, error: "erro"}
        }
    }
    
    async update(id, data){
        let params = {
            method: 'PATCH',
            headers: this.headers(),
            body: JSON.stringify({ settings: data})
        }
        let url = this.url + "/" + id
        try {
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter);
        }catch(error){
            return {success: false, error: "erro"}
        }
    }

}

export default SettingsApi;