import React from 'react';
import PropTypes from 'prop-types';
import {DatePicker, ErrorField} from 'components/Forms';
import "./style.scss"
class DatePickerWithValidation extends React.Component{

    constructor(props){
        super(props);
        // this.formatSelectedDate = this.formatSelectedDate.bind(this);
        // this.onChange = this.onChange.bind(this);
    }

    
    render(){
        let {valid, error, ...props} = this.props
        return(
            <React.Fragment>
                <DatePicker {...props} 
                            className="with-error"/>
                <ErrorField valid={valid}
                            error={error}></ErrorField>
            </React.Fragment>
        )
    }
}

DatePickerWithValidation.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedDate: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired
};

export default DatePickerWithValidation