
import * as actionTypes from 'store/actions/settings';

export const initialState = {
    settings: {
        cron_expression: "",
        error: {
            cron_expression: '',
        },
        valid: {
            cron_expression: true,
        }

    }
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.SET_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload.settings
                }
            };
        case actionTypes.SET_ERROR:
            return {
                ...state,
                settings: {
                    ...state.settings, 
                    error: {
                        ...state.settings.error,
                        [action.payload.name]: action.payload.error
                    },
                    valid: {
                        ...state.settings.valid,
                        [action.payload.name]: (action.payload.error.length === 0 ? true : false)
                    }
                }
            };
        case actionTypes.CHANGE_ATTR:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload.name]: action.payload.value    
                }
            };
        default:
            return state;
    }
}

export default reducer;