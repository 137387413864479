import * as yup from 'yup'
import "config/yup/pt-BR/locale_pt-BR.js"
import "config/yup/helpers/date_helpers.js"
import * as moment from 'moment'

function dateHigherThan(ref, msg) {
	return this.test({
		name: 'dateHigherThan',
		exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
            let date = this.resolve(ref);
			// debugger;
            if(date === "" || date === undefined){
                return false;
            }else{
                return moment(value).isBefore(moment(this.resolve(ref)));
            }
		}
	})
};

yup.addMethod(yup.string, 'dateHigherThan', dateHigherThan);

const colorsFormValidation = yup.object().shape({
    description_pt: yup.string().required(),
    description_en: yup.string().required(),
    description_es: yup.string().required(),
    detailed_description_pt: yup.string().required(),
    detailed_description_en: yup.string().required(),
    detailed_description_es: yup.string().required(),
    active:  yup.boolean().required(),
    code:  yup.string().required(),
    finish_id: yup.number().required(),
    geometry: yup.string().required(),
    image_orientation: yup.string().required(),
    image_initial_date: yup.string().required(),
    image_final_date: yup.string().required(),
    //
    
    //.dateHigherThan(yup.ref('image_final_date'), "Precisa ser anterior à data final!"), 
    // dates: yup.object({
    //     image_final_date: yup.string().required().dateLesserThan(yup.ref('image_initial_date'), "Precisa ser posterior à data inicial!"),
    //     image_initial_date: yup.string().required().dateHigherThan(yup.ref('image_final_date'), "Precisa ser anterior à data final!")
    // })
});

export default colorsFormValidation;    