import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {ErrorField} from 'components/Forms'

class SelectWithValidation extends React.Component{

    constructor(props){
        super(props);
        this.selectFromOptions = this.selectFromOptions.bind(this);
        this.getStyle = this.getStyle.bind(this);
    }
    
    selectFromOptions(id){
        let option = this.props.options.filter(option => option.value === id);
        return (option.length === 0 ) ? {} : option
    }


    getStyle(){
        if(this.props.valid){
            return {}
        }else{
            return {
                control: (provided, state) => ({ 
                    ...provided,
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "red"
                 })
            }
        }
    }

    render(){
        return(
            <React.Fragment>
                <Select {...this.props}
                        styles={this.getStyle()}
                        onChange={this.props.onChange}
                        value={this.selectFromOptions(this.props.value)}
                        options={this.props.options}>
                </Select>
                <ErrorField valid={this.props.valid} error={this.props.error}></ErrorField>
            </React.Fragment>
        );
    }
}

SelectWithValidation.defaultProps = {
    value: ""
}

SelectWithValidation.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

export default SelectWithValidation;