import React, { Component } from 'react';
import {FormFeedback, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {ButtonWithLoading} from 'components/Forms';
import logo from 'assets/img/brand/logo_florense.png';
import {Redirect, withRouter, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import Api from 'api/Api';
import { toast } from 'react-toastify';
import Helpers from 'util/helpers'
import * as actions from 'store/actions/users';
import validation from './validation';
import * as yup from 'yup';
import queryString from 'query-string'

class ResetPasswordForm extends Component {


    constructor(props){
        super(props);
        this.state = {
            password: "",
            password_confirmation: "",
            loading: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidMount(){
        this.headers = queryString.parse(this.props.location.search);
    }
    
    

    async handleChange(event){
        const name = event.target.name;
        const value = event.target.value;
        await this.props.actions.changeAttr(name, event.target.value);
        try{
            await validation.validateAt(name, this.props.user)
            this.props.actions.setError(name, "");
        }catch(err){
            this.props.actions.setError(name, err.errors);
        }
    }


    submit(){
        this.setState({loading: true})
        Api.Authentication.resetPassword(this.props.user.password, this.props.user.password_confirmation, this.headers).then(resp => {
            this.setState({loading: false})
            this.props.history.push("/password_changed");
        }).catch( error => {
            
            console.error(error);   
            toast.error(error.errors.full_messages)
            this.setState({loading: false})
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loading: true})
        validation.validate(this.props.user, { abortEarly: false}).then( (resp) => {
            this.submit();
        }).catch( (err) => {
            
            console.error(err);
            for( let error of err.inner){
                this.props.actions.setError(error.path, error.message);
            }
            this.setState({loadingSubmit: false});
            toast.error("Por favor, verifique os dados informados!");
        })
    }

    render() {
        // if(Helpers.UserHelper.isAuthenticated()){
        //     return(
        //         <Redirect to="/"></Redirect>
        //     )
        // }
        let {user} = this.props;
        return (
            <div className="app flex-row align-items-center">
                <Container> 
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <h3>Redefinição de Senha</h3>
                                            <p className="text-muted">Por favor, preencha os campos abaixo para redefinirmos sua senha!</p>

                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input  type="password" 
                                                        name="password"  
                                                        id="password"
                                                        value={user.password}
                                                        onChange={this.handleChange} 
                                                        invalid={!user.valid.password}
                                                        placeholder="Senha" 
                                                        autoComplete="current-password" />
                                                <FormFeedback >{(user.error.password.length > 0) ? 
                                                    user.error.password : ''}
                                                </FormFeedback>
                                            </InputGroup>

                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" name="password_confirmation"  
                                                        id="password_confirmation"
                                                        value={user.password_confirmation}
                                                        onChange={this.handleChange} 
                                                        invalid={!user.valid.password_confirmation}
                                                        placeholder="Confirmação de Senha" 
                                                        autoComplete="current-password" />
                                                        <FormFeedback >{(user.error.password_confirmation.length > 0) ? 
                                                            user.error.password_confirmation : ''}
                                                        </FormFeedback>
                                            </InputGroup>
                                            <Row>
                                                <Col xs="5">
                                                    <ButtonWithLoading outline loading={this.state.loading} color="primary outline" className="col-md-12 px-4">Login</ButtonWithLoading>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                                
                                <Card className="text-white py-5 d-md-down-none" style={{ backgroundColor: "black", width: '44%' }}>
                                    <CardBody className="text-center">
                                        {/* FLORENSE */}
                                        <div>
                                            <img src={logo} className="" alt="admin@florense.com.br" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            resetUser: () => {
                return dispatch({
                    type: actions.RESET_USER
                });
            },
            setUser: (user) => {
                return dispatch({
                    type: actions.SET_USER,
                    payload: {
                        user: {
                            ...user
                        }
                    }
                });
            },
            setError: (name, error) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
            },
            changeAttr: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name, 
                        value: value
                    }
                });
            }
        }
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm));
