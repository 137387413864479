import React from 'react';
import {connect} from 'react-redux';
import * as actions from "store/actions/colors"
import validation from '../../validation';
import * as yup from 'yup';
import SelectInput from '../SelectInput';
import {imageOrientationOptions} from 'constants/ColorConstants';

class ImageOrientationField extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            name: "image_orientation",
            valid: true
        }
        this.handleChange = this.handleChange.bind(this);
        // {label: "TesteName", value: "TesteValue"}]
    }

    componentDidMount(){
   
    }

    /**
     * 
     * @param {*} selected 
     * @param {*} event
     * 
     * Se for um registro novo, deve  
     */
    handleChange(selected, event){
        let {value} = selected;
        yup.reach(validation, this.state.name).validate(value, {abortEarly: false}).then( (resp) => {
            this.props.actions.setError(this.state.name, "")
        }).catch( (err) => {
            for(let error of err.inner){
                this.props.actions.setError(this.state.name, error.message)
            }
        })
        this.props.actions.change(this.state.name, value)
    }

    
    render(){
        return(
            <React.Fragment>
                <SelectInput
                    label="Panorama da Imagem"
                    placeholder="Escolha o panorama da imagem"
                    onChange={this.handleChange}
                    selectedValue={this.props.image_orientation}
                    options={imageOrientationOptions}
                    valid={this.props.error == "" ? true : false}
                    error={this.props.error}
                    ></SelectInput>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        image_orientation: state.colors.color.image_orientation,
        error: state.colors.errors.image_orientation
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            change: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ORIENTATION,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageOrientationField);