
import React from 'react';
import MaterialTable from "material-table";
import {baseOptions, translations} from 'config/MaterialTable/config';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from 'store/actions/users'
import Api from 'api/Api'
import {toast} from 'react-toastify';
import Helpers from 'util/helpers';
class UsersTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			actions:[
				{
            		isFreeAction: true,
					icon: 'add',
                    tooltip: 'Adicionar Usuário',
                    onClick: () => {
                        this.props.actions.resetUser()
                        this.props.history.push("/users/create") 
                    },
                    iconProps: {
                        className: "text-success icon-lg",
                    }
				},
                rowData => ({
                    icon: 'delete',
                    tooltip: 'Excluir Usuário',
                    onClick: this.delete,
                    iconProps: {
                        className: "text-danger"
                    }
                })
            ]
        }
        this.loadData = this.loadData.bind(this);
        this.delete = this.delete.bind(this);
    }

    loadData(){
        Api.Users.all().then( (resp) => {
            let {data} = resp;
            for(let user of data){
                user.role = Helpers.UserHelper.translateRole(user.role)
            }
            this.props.actions.setUsers(data);
        }).catch( (error) => {
            console.error(error);
            toast.error("Ops! Algo deu errado!");
        });
    }

    delete(event, rowData) {
    	let {id} = rowData;
		Api.Users.delete(id).then( (resp) => {
            this.loadData();
			toast.success("Usuário deletado com sucesso")
		}).catch( (err) => {
            console.error(err);
            toast.error("Ops! Algo deu errado!");
		})
    }

    componentDidMount(){
       this.loadData()
    }

    edit(event, rowData){
        this.props.actions.resetFinish();
		this.props.history.push('/users/update/' + rowData.id);	
    }
    
    render(){
        return(
            <div style={{ maxWidth: "100%" }}>
            <MaterialTable 
                columns={ columns } 
                title={ "Usuários" }
                data={ this.props.users }
                options={ baseOptions }
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                actions={ this.state.actions }
                localization={ translations }
            />
        </div>
        );
    }
};

const columns = [
    { title: "Id", field: "id"},
    { title: "Nome", field: "name"},
    { title: "Email", field: "email"},
    { title: "Grupo", field: 'role'},
    { title: "Tipo da Conta", field: 'sign_up_method'}
]

const mapStateToProps = (state) => {
    return {
         users: state.users.users,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
            actions: {
                resetUsers: () => {
                    return dispatch({
                        type: actions.RESET_USERS
                    });
                },
                resetUser: () => {
                    return dispatch({
                        type: actions.RESET_USER
                    });
                },
                setUsers: (users) => {
                    return dispatch({
                        type: actions.SET_USERS,
                        payload: {
                            users: users
                        }
                    });
                }
            }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersTable));