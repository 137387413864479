import React from 'react';
import { Form, FormFeedback, FormGroup, Label, Input, Button, Row, Col} from 'reactstrap'
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux'; 
import * as actions from 'store/actions/users';
import validation from './validation';
import * as yup from 'yup';
import Api from 'api/Api';
import {toast} from 'react-toastify';
class UsersForm extends React.Component {


    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
        let {id} = this.props.match.params
        if(id === undefined){
            return
        }else{
            Api.Users.get(id).then( (resp) => {
                let user = resp.data;
                this.props.actions.setUser(user);
                this.setState({url: user.image})
            }).catch( (err) => {
                toast.error("Não pode buscar Usuario!");
            })
        }
    }

    async submit(){ 
        let {error, valid, ...user} = this.props.user;
        try{
            if(user.id !== "" && user.id !== undefined){
                await Api.Users.update(user.id, user)
            }else{
                let resp = await Api.Authentication.sign_up(user);
                let _user = resp.data;
                await Api.Users.changeRole(_user.id);
            }
            toast.success("Usuario salvo com sucesso!");
            this.props.actions.resetUser();
            this.props.history.push("/users");
        }catch(error){
            console.error(error);
            
            if(error.status === 401){
                
            }else{
                let { full_messages, ...fields} = error.errors;
                for(let prop in fields){
                    this.props.actions.setError(prop, fields[prop])
                }    
            }
            toast.error("Ops! Algo deu errado!")
        }
    }


    async handleChange(event){
        const name = event.target.name;
        const value = event.target.value;
        await this.props.actions.changeAttr(name, event.target.value);
        if(name === "password" || name === "password_confirmation"){
            try{
                await validation.validateAt('password', this.props.user)
                await validation.validateAt('password_confirmation', this.props.user)
                this.props.actions.setError('password', "");
                this.props.actions.setError('password_confirmation', "");
            }catch(err){
                this.props.actions.setError('password', err.errors);
                this.props.actions.setError('password_confirmation', err.errors);
            }
        }else{
            try{
                await validation.validateAt(name, this.props.user)
                this.props.actions.setError(name, "");
            }catch(err){
                this.props.actions.setError(name, err.errors);
            }
        }
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loadingSubmit: true});
        console.log(this.props.user);
        validation.validate(this.props.user, { abortEarly: false}).then( (resp) => {
            this.submit();
        }).catch( (err) => {
            console.error(err);
            for( let error of err.inner){
                this.props.actions.setError(error.path, error.message);
            }
            this.setState({loadingSubmit: false});
            toast.error("Por favor, verifique os dados informados!");
        })
    }
    
    handleCheckChange(value){
        this.props.actions.toggleActive()
    }

    render(){
        let {user, role} = this.props
        if( role !== "admin"){
            toast.error("Apenas administradores podem criar usuários!")
            return <Redirect to="/users"></Redirect>
        }
        return(
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col xs="12">
                        <h2> Cadastro de Usuário</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="username">Nome</Label>
                            <Input  name="username" id="username"
                                    onChange={this.handleChange}
                                    value={user.username}
                                    invalid={!user.valid.username}
                                    ></Input>
                            <FormFeedback >{(user.error.username.length > 0) ? 
                                                    user.error.username : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input  name="email" id="email"
                                    onChange={this.handleChange}
                                    value={user.email}
                                    invalid={!user.valid.email}
                                    ></Input>
                            <FormFeedback >{(user.error.email.length > 0) ? 
                                                    user.error.email : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="password">Senha</Label>
                            <Input  type="password"
                                    name="password" id="password"
                                    onChange={this.handleChange}
                                    value={user.password}
                                    invalid={!user.valid.password}
                                    ></Input>
                            <FormFeedback >{(user.error.password.length > 0) ? 
                                                    user.error.password : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="password_confirmation">Confirmação de Senha</Label>
                            <Input  type="password" name="password_confirmation" id="password_confirmation"
                                    onChange={this.handleChange}
                                    value={user.password_confirmation}
                                    invalid={!user.valid.password_confirmation}
                                    ></Input>
                            <FormFeedback >{(user.error.password_confirmation.length > 0) ? 
                                                    user.error.password_confirmation : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs="12">
                        <Button color="success">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            resetUser: () => {
                return dispatch({
                    type: actions.RESET_USER
                });
            },
            setUser: (user) => {
                return dispatch({
                    type: actions.SET_USER,
                    payload: {
                        user: {
                            ...user
                        }
                    }
                });
            },
            setError: (name, error) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
            },
            changeAttr: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name, 
                        value: value
                    }
                });
            }
        }
    };
};

const mapStateToProps = (state) => {
    return {
        role: state.users.auth.role,
        token: state.users.auth.token,
        user: state.users.user,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersForm));