import React from 'react';
import PropTypes from 'prop-types'
import {Input, FormFeedback, FormGroup} from 'reactstrap'

class ErrorField extends React.Component {


    render(){
        return(
            <React.Fragment>
                <FormGroup>
                    <Input name="teste" hidden invalid={!this.props.valid}/>
                    <FormFeedback>{this.props.error}
                    </FormFeedback>
                </FormGroup>
            </React.Fragment>
        )
    }
}

ErrorField.defaultValues = {
    error: ""
}
//(this.props.error.lenght > 0) ? this.props.error: ''
ErrorField.propTypes = {
    valid: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired

};

export default ErrorField;