import * as yup from 'yup'
import * as moment from 'moment'

function dateLesserThan(ref, msg) {
	return this.test({
		name: 'lesserThan',
		exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {	
			let date = this.resolve(ref);
            if(date === "" || date === undefined){
                return false;
            }else{
                return moment(value).isAfter(moment(this.resolve(ref)));
            }
		}
	})
};


function dateHigherThan(ref, msg) {
	return this.test({
		name: 'dateHigherThan',
		exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
            let date = this.resolve(ref);
            if(date === "" || date === undefined){
                return false;
            }else{
                return moment(value).isBefore(moment(this.resolve(ref)));
            }
		}
	})
};

yup.addMethod(yup.string, 'dateLesserThan', dateLesserThan);
yup.addMethod(yup.string, 'dateHigherThan', dateHigherThan);