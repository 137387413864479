import React from 'react';
import {connect} from 'react-redux';
import DateInput from '../DateInput';
import * as actions from "store/actions/colors"
import validation from '../../validation';
import * as yup from 'yup';

class ImageInitialDateField extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            name: "image_initial_date",
            invalid: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
   
    }

    handleChange(name, value){

        this.props.actions.change(this.state.name, value)
    }

    render(){
        return(
            <React.Fragment>
                <DateInput
                    name={this.state.name}
                    placeholder="Insira data de Validade Inicial"
                    label="Data de Validade Inicial"
                    onChange={this.handleChange}
                    error={this.props.error}
                    selectedDate={this.props.image_initial_date}></DateInput>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        image_initial_date: state.colors.color.image_initial_date,
        error: state.colors.errors.image_initial_date
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            change: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageInitialDateField);