import React from 'react';




class HelpPage extends React.Component {


    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                <b>Cadastro de usuários</b>
                    <p>Através do botão Cadastrar, crie seu usuário informando seu nome, um e-mail válido e uma senha para 
                        ter acesso ao aplicativo.<br/>
                    É possível também acessar o aplicativo com seu usuário Facebook, clicando no ícone f.<br/>
                    Caso esqueça sua senha, no botão Esqueci a senha, informe seu e-mail para a recuperação. 
                    Em seguida você receberá um e-mail de <a href="mailto: contato@florense.com.br">contato@florense.com.br</a> com o 
                    assunto “Instruções de redefinição de senha”.
                    </p>
                <b>Usando o Creative</b>
                    <p>Navegue pelos menus para ter acesso aos acabamentos, projetos mood boards e perfil.<br/>
                    Dê um único clique sobre a imagem e selecione a amostra para incluir em um projeto mood board.<br/>
                    Pressione a imagem por 2 segundos e veja os detalhes do acabamento.<br/>
                    Altere o idioma, troque sua senha, veja os termos ou saia do aplicativo pelo menu Perfil.</p>
                <b>Criando mood boards</b>
                <p>
                    Selecione imagens pelo Mostruário para iniciar um novo projeto ou inicie/acesse um projeto pelo menu inferior.
                    Pressione a tela branca por 2 segundos para alterar o fundo. É possível carregar imagens da galeria e tirar fotos.
                    Reposicione e redimensione a imagem arrastando-a pelo mood board.
                    Dê um clique sobre a imagem para sobrepor, substituir ou excluir a amostra.
                    Adicione novas amostras, imagens da galeria e tire fotos pelo botão Adicionar.
                    Salve ou crie uma cópia do seu mood board pelo botão Projeto.
                    Compartilhe seu projeto pelo botão Enviar > Compartilhar.
                </p>
                <b>Sincronização das imagens</b>
                <p>
                    Se você estiver instalando seu aplicativo pela primeira vez, o tempo necessário para download das imagens será maior.<br/>
                    Como nosso mostruário é amplo, o aplicativo necessita atualizar muitas imagens.<br/>
                    Quando ocorrer alguma mudança no mostruário, não se preocupe, somente serão atualizadas as imagens alteradas, o que deixa o download bem rápido.
                </p> 
                <b>Enviar um feedback ou relatar um problema</b>
                <p>
                    Enviar um feedback ou relatar um problema <br/>
                    Envie comentários sobre o Creative e nossos recursos. <br/>
                    Visite nossos Termos para saber mais. <br/>
                    E-mail: <a href="mailto: creative@florense.com.br">creative@florense.com.br</a> <br/>
                    Telefone: (54) 3292-6360 <br/>
                </p>
 



 


 
            </div>
        );
    }
}

export default HelpPage