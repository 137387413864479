import {store} from 'store/store';

const mantainerNav =  {
  name: 'Cadastro de Amostras',
  icon: 'cui-dashboard',
  children: [
    {
      name: 'Acabamentos',
      url: '/finishes',
      icon: 'cui-layers',
      admin: false
    },
    {
      name: 'Cores',
      url: '/colors',
      icon: 'fa fa-paint-brush',
      admin: false
    }
  ]
}
const adminNav = 
{
  name: 'Cadastros Gerais',
  icon: 'fa fa-gear',
  children: [
    {
      name: "Termos de Uso",
      url: '/terms_of_use',
      icon: 'fa fa-file-text-o',
      admin: false
    },
    {
      name: "Usuários",
      url: '/users',
      icon: 'fa fa-user-circle',
      admin: true
    },
    {
      name: 'Ajuda',
      url: '/help',
      icon: 'fa fa-question',
      admin: false
    },
    // {
    //   name: "Configurações",
    //   url: '/settings',
    //   icon: 'fa fa-cogs',
    //   admin: true
    // }
  ]
}

class Nav {

    constructor(){
        this.mantainerNav = mantainerNav;
        this.adminNav = adminNav
        this.store = store;
    }
    
    getAuth(){
        let state = this.store.getState()
        return state.users.auth
    }

    getNav(){
      let auth = this.getAuth();
      let nav = {
        items: [
         
        ],
      }
      
      
      if(auth.role != "admin"){
        let adminNav = {
          ...this.adminNav,
          children: this.adminNav.children.filter( e => !e.admin) 
        }

        let mantainerNav = {
          ...this.mantainerNav,
          children: this.mantainerNav.children.filter( e => !e.admin) 
        }
        nav.items.push(mantainerNav)
        nav.items.push(adminNav)
      }else{
        nav.items.push(this.mantainerNav)
        nav.items.push(this.adminNav)
      }
      
      return nav;
    }
}



export default Nav;