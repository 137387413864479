import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import tokenExpiration from 'store/middleware/tokenExpirationReduxPersist';
import {TOKEN_EXPIRATION_TIME} from 'config/loginConfig';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import userReducer, { initialState as userInitialState} from 'store/reducers/users';
import FinishesReducer from 'store/reducers/finishes';
import ColorsReducer from 'store/reducers/colors';
import SettingsReducer from 'store/reducers/settings';
import AuthReducer from 'store/reducers/auth';
import TermsOfUse from 'store/reducers/termsOfUse';
import helpQuestions from 'store/reducers/helpQuestion'
import ColorsOldReducer from 'store/reducers/colors_old'

const persistConfig = {
    key: 'access_token',
    storage: storage, 
    whitelist: ['auth', 'access_token', 'expiry', 'client', 'uid'],
    stateReconciler: autoMergeLevel2,
}

export const combinedReducers = combineReducers(
    {
        users: persistReducer(persistConfig, userReducer),
        termsOfUse: TermsOfUse,
        finishes: FinishesReducer,
        colors: ColorsReducer,
        colorsOld: ColorsOldReducer,
        settings: SettingsReducer,
        helpQuestions: helpQuestions,
        auth: AuthReducer
    }
);