import React from 'react';
import HelpQuestionsTable from './components/HelpQuestionsTable'
class HelpQuestionsIndex extends React.Component {

    render(){
        return(
            <HelpQuestionsTable/>
        );
    }
}

export default HelpQuestionsIndex;