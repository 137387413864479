import {API_URL, AUTH_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter} from '../Filters';
import ApiBase from '../ApiBase'

class AuthenticationApi extends ApiBase{

    constructor(){
        super();
        this.url = API_URL + AUTH_URL;
    }
    
    async validateToken(token) {
        
        let params = {
            method: "GET",
            headers: this.headers(),
        }
        let url = this.url + "/validate_token"
        return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
    }

    async lostPassword(email){
        let params = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                redirect_url: process.env.REACT_APP_RESET_PASSWORD
            })
        }
        let url = this.url + "/password";
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw new Error({success: false, error: "erro"}); 
        }
    }

    async sign_up(data){
        let params = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: data.username,
                ...data
            })
        }
        let url = this.url;
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }
    }
    
    async resetPassword(password, password_confirmation, headers){
        let params = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                uid: headers.uid, 
                client: headers.client,
                'access-token': headers["access-token"]
            },
            body: JSON.stringify({
                password_confirmation: password_confirmation,
                password: password
            })
        }
        let url = this.url + "/password";
        return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
    }
    async login(email, password){
        let params = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        }
        let url = this.url + "/sign_in";
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            
            throw {success: error.success, errors: error.errors}; 
        }
    }
    
    async logout(){
        let auth = this.getAuth();
        let params = {
            method: 'DELETE',
            headers: this.headers(),
        }
        let url = this.url + '/sign_out'
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.succes, errors: error.errors}
        }
    }
}

export default AuthenticationApi;