import React from 'react';
import {connect} from 'react-redux';
import * as actions from "store/actions/colors"
import validation from '../../validation';
import * as yup from 'yup';
import SelectInput from '../SelectInput';
import {imageGeometryOptions} from 'constants/ColorConstants';

class GeometryField extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            name: "geometry",
            valid: true
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
   
    }

    handleChange(event){
        let {value} = event
        yup.reach(validation, this.state.name).validate(value, {abortEarly: false}).then( (resp) => {
            this.props.actions.setError(this.state.name, "")
        }).catch( (err) => {
            for(let error of err.inner){
                this.props.actions.setError(this.state.name, error.message)
            }
        })
        this.props.actions.change(this.state.name, value)
    }

    
    render(){
        return(
            <React.Fragment>
                <SelectInput
                    label="Geometria"
                    placeholder="Escolha a Geometria"
                    onChange={this.handleChange}
                    selectedValue={this.props.geometry}
                    options={imageGeometryOptions}
                    isDisabled={this.props.isDisabled}
                    valid={this.props.error == "" && this.props.geometry ? true : this.props.isDisabled ? true : false}
                    error={this.props.error || 'Selecione uma opção'}
                    ></SelectInput>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
         geometry: state.colors.color.geometry,
         error: state.colors.errors.geometry
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            change: async (name, value) => {
                await dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name,
                        value: value
                    }
                });
                return dispatch({
                    type: actions.CHANGE_GEOMETRY,
                    payload: {
                        value: value
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeometryField);