import React from 'react';
import FinishesTable from './components/FinishesTable'
class FinishesIndex extends React.Component {

    render(){
        return(
            <FinishesTable/>
        );
    }
}

export default FinishesIndex;