import React from 'react';
import * as actions from "store/actions/colors"
import {ImageField, SelectWithValidation} from 'components/Forms';
import {connect} from 'react-redux';

class ImageInput extends React.Component {

    constructor(props){
        super(props)
        // this.handleChange = this.handleChange.bind(this);
    }
    
    render(){
        return(
            <React.Fragment>
                <ImageField
                    title={this.props.title}
                    fileRef={this.props.fileRef} 
                    inputId={this.props.name}
                    url={this.props.url}
                    onChange={this.props.onChange}>
                    </ImageField>
            </React.Fragment>
        );
    }
}

export default ImageInput;