import React from 'react'
import { Form, FormFeedback, FormGroup, Label, Input, Button, Row, Col} from 'reactstrap'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'; 
import * as actions from 'store/actions/settings';
import validation from './validation';
import * as yup from 'yup';
import Api from 'api/Api';
import {toast} from 'react-toastify';
// import {ImageField} from 'components/Forms';
// import ActiveField from 'components/Forms/ActiveField'

class SettingsForm extends React.Component{

    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        Api.Settings.all().then( (resp) => {
            let settings = resp.data;
            this.props.actions.setSettings(settings);
        }).catch( (err) => {
            toast.error("Não pode buscar Configurações!");
        })
    }


    handleSubmit(event){
        event.preventDefault();
        let {settings} = this.props;
        Api.Settings.update(settings.id, {
            cron_expression: settings.cron_expression
        }).then( (resp) => {
            console.log(resp);
            toast.success("Configurações atualizadas com sucesso!");
        }).catch( (error) => {
            console.error(error);
        })
    }

    handleChange(event){
        const value = event.target.value;
        const name = event.target.name;
        this.props.actions.changeAttr(name, value);
        yup.reach(validation, name).validate(value).then( (resp) => {
            this.props.actions.setError(name, "");
        }).catch( (err) => {
            this.props.actions.setError(name, err.errors);
        })
    }

    render(){
        let {settings} = this.props;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col xs="12">
                        <h2> Manutenção de Configurações</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="cron_expression">Código da expressão Cron</Label>
                            <Input  name="cron_expression" id="cron_expression"
                                    onChange={this.handleChange}
                                    value={settings.cron_expression}
                                    invalid={!settings.valid.cron_expression}
                                    ></Input>
                            <FormFeedback >{(settings.error.cron_expression.length > 0) ? 
                                                    settings.error.cron_expression : ''}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs="12">
                        <Button color="success">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            setSettings: (settings) => {
                // settings.id = (settings.id === null) ? "" : settings.id
                // settings.description_pt = (settings.description_pt === null) ? "" : settings.description_pt
                // settings.description_es = (settings.description_es === null) ? "" : settings.description_es
                // settings.description_en = (settings.description_en === null) ? "" : settings.description_en
                // settings.code = (settings.code === null) ? "" : settings.code
                return dispatch({
                    type: actions.SET_SETTINGS,
                    payload: {
                        settings: settings
                    }
                });
            },
            setError: (name, error) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
            },
            changeAttr: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name, 
                        value: value
                    }
                });
            }
        }
    };
};

const mapStateToProps = (state) => {
    return {
        token: state.users.auth.token,
        settings: state.settings.settings,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsForm));