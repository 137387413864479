export const baseOptions = {
    filtering: true,
    actionsColumnIndex: -1	
}

export const translations = {
	pagination: {
        labelDisplayedRows: '{from}-{to} de {count}',
        labelRowsSelect: 'linhas'
    },
    toolbar: {
        nRowsSelected: '{0} linha(s) selecionada(s)',
        searchPlaceholder: 'Filtro'
    },
    header: {
        actions: 'Ações'
    },
    body: {
        emptyDataSourceMessage: 'Nenhum dado encontrado!',
        filterRow: {
            filterTooltip: 'Filtro'
        }
    }
}