import React from 'react'
import PropTypes from 'prop-types'
import {Card, CardImg, CardTitle, Row, Col, CardBody} from 'reactstrap'
import {convertFileToBase64} from 'util/convertFileToBase64';
import {toast} from 'react-toastify';
class ImageField extends React.Component{


    constructor(props){
        super(props)
        this.state = {
            url: "",
            filename: ""
        }
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount(){
        this.setState({url: this.props.url})
    }
    componentDidUpdate(prevProps) {
        if (this.props.url !== prevProps.url) {
            this.setState({url: this.props.url})
        }
    }

    async onFileChange(event){
        let file = event.target.files[0];
        try{
            let url = URL.createObjectURL(file)
            this.setState({
                url: url,
                filename: file.name
            })
            let resp = await convertFileToBase64(file)
            this.props.onChange(resp);
        }catch(e){
            console.error(e);
            toast.error("Erro ao converter arquivo enviado, por favor, selecione novamente!");
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className={this.props.visible ? "" : "d-none"}>
                    <Card>
                        <CardTitle className=" mt-2 text-center">
                            <p>{this.props.title}</p>
                        </CardTitle>
                        <CardImg top width="100%" src={this.state.url} alt="" />
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <label>
                                        <Row>
                                            <label className="ml-2 mb-2">{this.state.filename}</label>
                                        </Row>
                                        <Row>
                                            <span style={{ color: "white", backgroundColor: "#1EA0E8"}} className="ml-2 btn">Importar Imagem</span>
                                        </Row>
                                        <input type="file" accept=".png" className="invisible" id={this.props.inputId} onChange={this.onFileChange} 
                                                ref={this.props.fileRef} name={this.props.name}
                                                aria-describedby="inputGroupFileAddon01"/>

                                    </label>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}
ImageField.defaultProps = {
    title: "",
    name: 'fileinput',
    inputId: "fileinput",
    url: "",
    visible: true
};

ImageField.propTypes = {
    title: PropTypes.string,
    fileRef: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    visible: PropTypes.bool.isRequired
}

export default ImageField;