import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import logo from 'assets/img/brand/logo_florense.png';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'; 
import * as actions from 'store/actions/auth';
import Api from 'api/Api';
import { toast } from 'react-toastify';
class ForgotPasswordForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            username: "",
            proccessed: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }


    handleSubmit(event){
        event.preventDefault();
        Api.Authentication.lostPassword(this.state.username).then(resp => {}).catch(error => {
            console.error(error);
        })
        toast.success("As instruções de redefinição de senha foi enviado para o email associado à esta conta!")
        this.setState({processed: !this.state.processed})
        // this.props.history.push("/login");
    }

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <div hidden={!this.state.processed}>
                                            <h1>Senha redefinida com sucesso</h1>
                                            <p className="text-muted">
                                              Você ja pode fazer login com a nova senha!
                                            </p>

                                        </div>
                                        <Form onSubmit={this.handleSubmit} hidden={this.state.processed}>
                                            <h1>Esqueci minha senha</h1>
                                            <p className="text-muted">Por favor, digite o email associado à sua conta para recuperarmos sua senha!</p>

                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-user"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" name="username" placeholder="Email" autoComplete="email"             
                                                            onChange={this.handleChange}
                                                            value={this.state.username}
                                                />
                                            </InputGroup>
                                            <Row>
                                                <Col xs="4">
                                                    <Button outline color="primary outline" className="px-4">Enviar</Button>
                                                </Col>
                                                <Col xs={{ size: 4, offset: 4}}>
                                                    <Button outline onClick={(e) => this.props.history.push("/login")} color="success outline" className="px-4">Voltar</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                                <Card className="text-white py-5 d-md-down-none" style={{ backgroundColor: "black", width: '44%' }}>
                                    <CardBody className="text-center">
                                        {/* FLORENSE */}
                                        <div>
                                            <img src={logo} className="" alt="admin@florense.com.br" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(ForgotPasswordForm);
