
export const API_URL = (process.env.REACT_APP_API_URL !== undefined) ? process.env.REACT_APP_API_URL :  "http://localhost:4002/api/v1"
export const FINISHES_URL = "/finishes"
export const COLORS_URL = "/colors"
export const MENU_ITEMS_URL = "/menu_items"
export const LOGS_URL = "/logs"
export const SETTINGS_URL = "/settings"
export const AUTH_URL = "/auth"
export const TERMS_OF_USE = '/terms_of_uses'
export const USER_URL = '/users'
export const HELP_QUESTIONS_URL = "/help"