
import {API_URL, FINISHES_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter, syncJsonFilter} from '../Filters';
import ApiBase from '../ApiBase';

class FinishesApi extends ApiBase {
    constructor(){
        super()
        this.url = API_URL + FINISHES_URL;
    }

    async get(id){
        let params = {
            method: 'GET',
            headers: this.headers(),
        }
        let url = this.url +"/" + id;
        try{
            let request = await fetch(url, params)  
            return await request.json()
        }catch(error){
            return {success: false, error: "erro"}
        }
    }

    async all(){
        let params = {
            method: 'GET',
            headers: this.headers(),
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(syncJsonFilter)
        }catch(error){
            return {success: false, error: "erro"}
        }
        
    }

    async update(id, data){
        let params = {
            method: 'PATCH',
            headers: this.headers(),
            body: JSON.stringify({ finish: data})
            }
        let url = this.url + "/" + id;
        return await fetch(url, params).then(errorStatusFilter).then(syncJsonFilter)
    }

    async create(data){
        let params = {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify({ finish: {
                ...data,
                active: true
            }})
        }
        // try{
            return await fetch(this.url, params).then(errorStatusFilter).then(jsonFilter)
        // }
        // catch(error){
        //     
        //     throw {success: error.success, errors: error.errors}; 
        // }
    }

}

export default FinishesApi;