
import {API_URL, HELP_QUESTIONS_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter} from '../Filters';
import ApiBase from '../ApiBase'
class HelpQuestionsApi extends ApiBase {
    
    constructor(){
        super()
        this.url = API_URL + HELP_QUESTIONS_URL;
    }

    async all() {
        let params = {
            headers: this.headers(),
            method: 'GET',
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }
    }

    async create(help){
        let params = {
            headers: this.headers(),
            body: JSON.stringify({
                help_question: help
            }),
            method: 'POST',
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }

    }

    async update(id, help){
        let params = {
            headers: this.headers(),
            body: JSON.stringify({
                help_question: help
            }),
            method: 'PATCH',
        }
        let url = this.url + "/" + id
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }
    }

    async delete(id){
        let params = {
            headers: this.headers(),
            method: 'DELETE',
        }
        let url = this.url + "/"+ id
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }
    }

    async get(id){
        let params = {
            headers: this.headers(),
            method: 'GET',
        }
        let url = this.url + "/" + id

        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }
    }

    async sign_up(data){
        let params = {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify({
                name: data.username,
                ...data
            })
        }
        let url = this.url;
        try{
            return await fetch(url, params).then(errorStatusFilter).then(jsonFilter)
        }
        catch(error){
            throw {success: error.success, errors: error.errors}; 
        }
    }
}

export default HelpQuestionsApi;
