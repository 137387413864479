import React from 'react'
import {Form, FormFeedback, Input, Label, Row, Col, FormGroup} from 'reactstrap';
import * as actions from 'store/actions/termsOfUse';
import {ButtonWithLoading} from 'components/Forms'
import {connect} from 'react-redux';
import Api from 'api/Api';
import ReactQuill from 'react-quill'; // ES6
import {toast} from 'react-toastify';
import { termsOfUseFormValidation } from './validation/';
import * as yup from 'yup';
import {modules, formats} from 'config/ReactQuill/config'
import { withRouter } from 'react-router-dom';
/**
 * Css
 */
import 'react-quill/dist/quill.snow.css';

class TermsOfUseForm extends React.Component {


    constructor(props){
        super(props);
        this.state = {
            loadingSubmit: false
        }
        this.handleValidationAndSubmit = this.handleValidationAndSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleQuillChange = this.handleQuillChange.bind(this); 
        this.submit = this.submit
        this.populateErrors = this.populateErrors.bind(this);
    }

    componentDidMount(){
        Api.TermsOfUse.get().then( (resp) => {
            let terms_of_use = resp.data
            this.props.actions.setTermsOfUse(terms_of_use);
        }).catch( (err) => {
            console.error(err)
            toast.error("Não pode buscar terms de uso!");
        })
    }

    handleQuillChange(content, id){
        this.props.actions.changeAttr(id, content);
        yup.reach(termsOfUseFormValidation, id).validate(content)
                .then( (resp) => {
                    this.props.actions.setError(id, "");
                }).catch( (err) => {
                    this.props.actions.setError(id, err.errors);
                })
    }
    handleSelectChange(selectedValue){
        this.props.actions.changeAttr("language", selectedValue.value)
        this.props.actions.setError("language", "");
    }

    handleChange(event){
        const value = event.target.value;
        const name = event.target.name;
        this.props.actions.changeAttr(name, value);
        yup.reach(termsOfUseFormValidation, name).validate(value).then( (resp) => {
            this.props.actions.setError(name, "");
        }).catch( (err) => {
            this.props.actions.setError(name, err.errors);
        })
    }

    populateErrors(body){
        for( let name in body ){
            this.props.actions.setError(name, body[name][0])
        }
    }

    async submit(){
        let {termsOfUse} = this.props;
        let resp = {}
        try{
            resp = await Api.TermsOfUse.update(termsOfUse);
        
        } catch(error){
            console.log(error);
            this.setState({loadingSubmit: false})
            if(error.body.status == 500){
                toast.error("Ops! Algo deu errado! Contate o administrador.")
            }else{
                this.populateErrors(error.body)
            }
            return;
        }
        this.setState({loadingSubmit: false})
        toast.success("Termos de Uso salvo com sucesso!");
    }

    handleValidationAndSubmit(event){
        event.preventDefault();
        this.setState({loadingSubmit: true});
        termsOfUseFormValidation.validate(this.props.termsOfUse, { abortEarly: false})
            .then( (resp) => {
                this.submit()
            }).catch( (err) => {
                for( let error of err.inner){
                    this.props.actions.setError(error.path, error.message);
                }
                this.setState({loadingSubmit: false});
                toast.error("Por favor, verifique os dados informados!");
            })
    }

    render(){
        let {termsOfUse} = this.props;
        return(
            <div>
                <Form onSubmit={this.handleValidationAndSubmit}>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label for="body">Termos em Português</Label>
                                <ReactQuill theme="snow"
                                            name="body"
                                            modules={modules} 
                                            formats={formats}
                                            value={termsOfUse.body}
                                            onChange={(content) => this.handleQuillChange(content, "body")} />
                                
                                <Input hidden invalid={true}></Input> 
                                <FormFeedback >{(termsOfUse.error.body.length > 0) ? 
                                                    termsOfUse.error.body : ''}
                                </FormFeedback>  
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label for="body">Termos em Inglês</Label>
                                <ReactQuill theme="snow"
                                            id="body_english"
                                            name="body_english"
                                            modules={modules} 
                                            formats={formats}
                                            value={termsOfUse.body_english}
                                            onChange={(content) => this.handleQuillChange(content, "body_english")} />
                                
                                <Input hidden invalid={true}></Input> 
                                <FormFeedback >{(termsOfUse.error.body_english.length > 0) ? 
                                                    termsOfUse.error.body_english : ''}
                                </FormFeedback>  
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label for="body">Termos em Espanhol</Label>
                                <ReactQuill theme="snow"
                                            name="body_spanish"
                                            modules={modules} 
                                            formats={formats}
                                            value={termsOfUse.body_spanish}
                                            onChange={(content) => this.handleQuillChange(content, "body_spanish")} />
                                <Input hidden invalid={true}></Input> 
                                <FormFeedback >{(termsOfUse.error.body_spanish.length > 0) ? 
                                                    termsOfUse.error.body_spanish : ''}
                                </FormFeedback>  
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
                            <ButtonWithLoading loading={this.state.loadingSubmit} type="submit" 
                                           value="submit" className="col-md-2" color="success">
                                Salvar
                            </ButtonWithLoading>         
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.users.auth.token,
        termsOfUse: state.termsOfUse.termsOfUse,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            setTermsOfUse: (termsOfUse) => {
                termsOfUse.id = (termsOfUse.id === null) ? "" : termsOfUse.id
                termsOfUse.body = (termsOfUse.body === null) ? "" : termsOfUse.body
                termsOfUse.body_english = (termsOfUse.body_english === null) ? "" : termsOfUse.body_english
                termsOfUse.body_spanish= (termsOfUse.body_spanish === null) ? "" : termsOfUse.body_spanish
                return dispatch({
                    type: actions.SET_TERMS_OF_USE,
                    payload: {
                        termsOfUse: termsOfUse
                    }
                })
            },
            setError: (name, error) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                })
            },
            changeAttr: (name, value) => {
                return dispatch({
                    type: actions.CHANGE_ATTR,
                    payload: {
                        name: name, 
                        value: value
                    }
                })
            }
        }
    };
};


export default withRouter((connect(mapStateToProps, mapDispatchToProps)(TermsOfUseForm)));