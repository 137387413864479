import React from 'react';
import { FormGroup, Input, FormFeedback, Label} from 'reactstrap'
import PropTypes from 'prop-types';
import Select from 'react-select';
import {ErrorField} from 'components/Forms'
class SelectInput extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selectedValue: {}
        }   
        this.getStyle = this.getStyle.bind(this)
        this.selectFromOptions = this.selectFromOptions.bind(this);
    }

    componentDidMount(){
    }

    getStyle(){
        if(this.props.valid){
            return {}
        }else{
            return {
                control: (provided, state) => ({ 
                    ...provided,
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "red"
                 })
            }
        }
    }

    selectFromOptions(id){
        let option = this.props.options.filter(option => option.value === id);
        return (option.length === 0 ) ? {} : option
    }
    render(){
        return(
            <FormGroup>
              
                <Label>{this.props.label}</Label>
                <Select {...this.props} 
                        styles={this.getStyle()}
                        onChange={this.props.onChange}
                        isDisabled={this.props.isDisabled}
                        value={this.selectFromOptions(this.props.selectedValue)}
                        options={this.props.options}>
                </Select>
                <ErrorField valid={this.props.valid}
                                    error={this.props.error}></ErrorField>
            </FormGroup>
        );
    }
}

SelectInput.defaultProps = {
    invalid: false,
    isDisabled: false
};

SelectInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    // value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired
};

export default SelectInput;
