
import FinishesForm from 'pages/Finishes/FinishesForm';
import FinishesIndex from 'pages/Finishes/FinishesIndex';
import ColorsIndex from 'pages/Colors/ColorsIndex/';
import ColorAndImagesForm from 'pages/Colors/ColorForm';
import LogsTable from 'pages/Logs/LogsTable';
import SettingsForm from 'pages/Settings';
// import TermsOfUseIndex from 'pages/TermsOfUse/TermsOfUseIndex';
import UsersIndex from 'pages/Users/UsersIndex';
import UsersForm from 'pages/Users/UsersForm';
import HelpQuestionsIndex from 'pages/HelpQuestions/HelpQuestionsIndex';
import HelpQuestionsForm from 'pages/HelpQuestions/HelpQuestionsForm';

import TermsOfUseForm from 'pages/TermsOfUse/TermsOfUseForm';
const routes = [
    { path: ['/', '/finishes'], exact: true, name: "Acabamentos", component: FinishesIndex },
    { path: '/finishes/create', exact: true, name: "Criar Acabamento", component: FinishesForm },
    { path: '/finishes/update/:id', exact: true, name: "Editar Acabamento", component: FinishesForm },
    { path: '/colors', exact: true, name: "Cores", component: ColorsIndex },
    { path: '/colors/create', exact: true, name: "Criar Cor", component: ColorAndImagesForm },
    { path: '/colors/update/:id', exact: true, name: "Editar Cor", component: ColorAndImagesForm },
    { path: '/logs', exact: true, name: "Logs", component: LogsTable},
    { path: '/settings', exact: true, name: "Configurações", component: SettingsForm },
    { path: '/terms_of_use', exact: true, name: "Termos de Uso", component: TermsOfUseForm },
    { path: '/users', exact: true, name: "Usuários", component: UsersIndex},
    { path: '/users/create', exact: true, name: "Criar Usuário", component: UsersForm},
    { path: '/help', exact: true, name: "Ajuda", component: HelpQuestionsIndex},
    { path: '/help/create', exact: true, name: "Criar Ajuda", component: HelpQuestionsForm },
    { path: '/help/update/:id', exact: true, name: "Editar Ajuda", component: HelpQuestionsForm },
]

export default routes;