
import {toast} from 'react-toastify';

export const errorStatusFilter = (resp) => {
    return new Promise((resolve, reject) => {
        if(resp.status >= 200 && resp.status <= 300){
            return resolve(resp);
        }else{
            resp.json().then((json) => {
                console.error(json);
                if(json.error){
                    toast.error(json.error.detail)
                }
                return reject(json);
            }).catch((error) => {
                return reject({error: error})
            })
        }
    })
}

export const syncJsonFilter =  async (resp) => {
    if(resp.status === 204){
        return {}
    }else{
        return await resp.json();
    }
}

export const jsonFilter = (resp) => {
    if(resp.status === 204){
        return new Promise((resolve, reject) => { 
            resolve({})
        });
    }else{
        return resp.json();
    }
}