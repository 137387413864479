import {store} from 'store/store';
import {API_URL, MENU_ITEMS_URL} from 'config/Api/urls';
import {errorStatusFilter, jsonFilter, syncJsonFilter} from '../Filters';

class MenuItemsApi {
    constructor(url){
        let state = store.getState();
        this.token = state.users.auth.token;
        this.url = API_URL + MENU_ITEMS_URL;
    }

    async get(id){
        let params = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.token
            }
        }
        let url = this.url +"/" + id;
        try{
            let request = await fetch(url, params)  
            return await request.json()
        }catch(error){
            return {success: false, error: "erro"}
        }
    }

    async all(){
        let params = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.token
            }
        }
        try{
            return await fetch(this.url, params).then(errorStatusFilter).then(syncJsonFilter)
        }catch(error){
            return {success: false, error: "erro"}
        }
        
    }

    async update(id, data){
        let params = {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.token
            },
            body: JSON.stringify({ menu_item: data})
            }
        let url = this.url + "/" + id;
        let request = await fetch(url, params)  
        return await request.json()
    }

    async create(data){
        let params = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.token
            },
            body: JSON.stringify({ menu_item: {
                ...data,
                active: true
            }})
        }
        let request = await fetch(this.url, params).then(errorStatusFilter).then(jsonFilter)
        return await request.json()
    }
}

export default MenuItemsApi;