import React from 'react';
import ReactQuill from 'react-quill'; // ES6
import Api from 'api/Api';

import "assets/quill_custom.css"

class TermosDeUsoPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            terms: ""
        }
    }

    async componentDidMount(){
        try{
            let response = await Api.TermsOfUse.get()
            let terms = response.data.body
            this.setState({terms})
        }catch(error){
            console.error(error);
        }
    }

    render(){
        return(
            <div>
                <h1 className="text-center  mt-2full-width">
                    Termos de Uso
                </h1>
                <ReactQuill 
                modules={{
                    toolbar: []
                }}
                value={this.state.terms} 
                readOnly
                />
            </div>
        );
    }

}


export default TermosDeUsoPage;