import * as yup from 'yup';
import "config/yup/pt-BR/locale_pt-BR.js";


const cron_expression_validation  = {
    regex: /(((\*|\?|\d+((\/|\-){0,1}(\d+))*)\s*){5})$/,
    message: "Precisa ser uma expressão Cron"
}

// const cron_expression_regex = /(hi|bye)/

const settingsFormValidation = yup.object().shape({
    // cron_expression: yup.number()
    cron_expression: yup.string().matches(cron_expression_validation.regex, { message: cron_expression_validation.message, excludeEmptyString: true }).required()
});

export default settingsFormValidation;