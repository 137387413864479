import React from 'react';
// import { FormGroup, Input, FormFeedback, Label} from 'reactstrap'
import {connect} from 'react-redux'; 
import StringInput from "../StringInput"
import * as actions from "store/actions/colors"
import validation from '../../validation';
import * as yup from 'yup';
import ImageInput from '../ImageInput';

class HorizontalImageInput extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            name: "horizontal",
            invalid: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.horizontalFileRef = React.createRef();
    }

    componentDidMount(){
   
    }

    handleChange(file){
        this.props.actions.change(this.state.name, file)
    }

    
    render(){
        return(
            <React.Fragment>
                <ImageInput
                    title={"Imagem para Mostruário" }
                    fileRef={this.horizontalFileRef}
                    inputId={this.state.name}
                    onChange={this.handleChange}
                    url={this.props.image.url}>
                </ImageInput>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        image: state.colors.images.horizontal,
        error: state.colors.errors.detailed_description_pt
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions:{
            setError: (name, value) => {
                return dispatch({
                    type: actions.SET_ERROR,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            },
            change: (name, value) => {
                return dispatch({
                    type: actions.SET_IMAGE,
                    payload: {
                        name: name,
                        value: value
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalImageInput);